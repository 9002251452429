<template>
  <div>
    <el-dialog title="" :visible.sync="dialogVisible" width="30%">
      <span>是否通过审核</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sure">通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogVisible: false,
      id: "",
      orderId:'',
    };
  },
  methods: {
    open(id) {
      this.orderId = id;
     // console.log(row, "1111111");
      this.dialogVisible = true;
    },
    // 确定
    sure() {
      this.$axios({
        method: "post",
        url:
          "/otaHotel/finance/examine/examineReceiptOrder?orderId=" +
          this.orderId +
          "&receiptState=1",
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      })
        .then((res) => {
          console.log(res,'res');
          this.getList();
          this.dialogVisible = false;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="less" scoped>
</style>