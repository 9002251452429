<template>
  <!--异常待转 转款 -->
  <div>
    <el-dialog title="转款" :visible.sync="dialogVisible" width="30%">
      <div class="cont">
        <div>
          <span>转款账户</span>
          <el-select v-model="value" placeholder="请选择" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.accountName + '(' + item.appId + ')'"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div>
          <span>剩余冲抵款</span>
          <el-input
            v-model="surplus"
            placeholder="剩余充抵款"
            size="mini"
            :disabled="true"
          ></el-input>
        </div>
        <div>
          <span>使用冲抵款</span>
          <el-input
            v-model="use"
            placeholder="请输入使用冲抵款金额"
            size="mini"
          ></el-input>
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="close">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPayConfigListAndOffsetPrice } from "../../../apis/Finance";
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogVisible: false,
      surplus: "",
      use: 0,
      options: [],
      value: "",
      id: "",
    };
  },
  methods: {
    //打开弹窗 获取数据
    async open(row) {
      this.id = row.id;
      this.dialogVisible = true;
      let { code, data } = await getPayConfigListAndOffsetPrice({
        orderId: row.id,
      });
      if (code == 0) {
        console.log(data, "list");
        this.options = data.payConfigs;
        this.surplus = data.offsetPrice;
      }
    },
    // 确定
    close() {
      this.$axios({
        method: "post",
        url:
          "/otaHotel/finance/transfer/transfer?orderId=" +
          this.id +
          "&useOffsetPrice=" +
          this.use +
          "&accountId=" +
          this.value,
        data: {
          //   orderId: this.id,
          //   useOffsetPrice: this.use == "" ? 0 : this.use,
          //   accountId: this.value,
        },
      })
        .then((res) => {
          this.use = 0;
          this.getList();
        })
        .catch((error) => {});
      //
      this.dialogVisible = false;
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.cont {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    margin: 5px 0;
    span {
      width: 100px;
      text-align: right;
      padding: 0 10px;
    }
  }
}
.el-select {
  width: 100% !important;
}
</style>