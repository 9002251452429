<template>
  <!-- 转账 -->
  <div class="carryover">
    <!-- <PublicForm /> -->
    <CarryoverForm />
  </div>
</template>

<script>
// import PublicForm from "./PublicForm";
import CarryoverForm from "./CarryoverForm.vue";
export default {
  components: {  CarryoverForm },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
</style>