<template>
  <div class="examineform">
    <div class="head">
      <!-- 按钮选项 -->
      <div class="title">
        <div class="btn">
          <el-button
            size="mini"
            v-if="role_finance_examine_invoice"
            :class="btnCur == 0 ? 'active' : ''"
            @click="btnFn(0)"
            >发票审核</el-button
          >
          <el-button
            size="mini"
            v-if="role_finance_examine_receipt"
            :class="btnCur == 1 ? 'active' : ''"
            @click="btnFn(1)"
            >回执审核</el-button
          >
        </div>
        <el-button
          size="mini"
          class="update"
          @click="openDiaglog"
          v-if="btnCur == '0'&&role_finance_examine_uploadinvoice"
          >上传发票</el-button
        >
      </div>
      <Invoice v-if="btnCur == '0'" />
      <Receipt v-if="btnCur == '1'" />
      <UploadInvoiceDiagLog ref="upload" />
    </div>
  </div>
</template>

<script>
import Receipt from "./Receipt.vue";
import Invoice from "./Invoice.vue";
import UploadInvoiceDiagLog from "./UploadInvoiceDiagLog.vue";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: { Receipt, Invoice, UploadInvoiceDiagLog },
  data() {
    return {
      role_finance_examine_invoice: getRoleKey("role_finance_examine_invoice"),
      role_finance_examine_receipt: getRoleKey("role_finance_examine_receipt"),
      role_finance_examine_uploadinvoice: getRoleKey(
        "role_finance_examine_uploadinvoice"
      ),
      btnCur: "0",
    };
  },
  methods: {
    // 选项卡点击事件
    btnFn(index) {
      this.btnCur = index;
    },
    openDiaglog() {
      this.$refs.upload.open();
    },
  },
};
</script>

<style lang="less" scoped>
.examineform {
  width: 100%;
  background: #fff;
  margin-top: 20px;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  padding: 12px 20px;
  box-sizing: border-box;
  .head {
    display: flex;
    flex-direction: column;
    .btn {
      padding: 10px;
      box-sizing: border-box;
      .el-button {
        border: 1px solid #256def;
      }
      .el-button.active {
        background: #256def;
        color: #fff;
      }
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  .el-button {
    border: 1px solid #256def;
  }
}
.update {
  width: 70px;
  height: 28px;
  padding: 0;
  border: none !important;
  background: #33b93f;
  color: #fff;
}
</style>

