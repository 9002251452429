<template>
  <div class="carryover">
    
    <AdvanceForm />
  </div>
</template>

<script>
import AdvanceForm from "./AdvanceForm.vue";
export default {
  components: { AdvanceForm },
  data() {
    return {
      formInline: {
        hotelName: "",
        writeHotelName: "",
        times: "",
        group: "",
        people: "",
      },
      //下拉框
      options: [
        {
          value: "选项1",
          label: "白班",
        },
        {
          value: "选项2",
          label: "夜班",
        },
        {
          value: "选项3",
          label: "凌晨班",
        },
      ],
      //日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  .el-form-item {
    margin: 0 10px;
  }
}
/deep/.el-form--inline .el-form-item__content {
  display: flex;
}
/deep/.el-form .el-form-item {
  display: flex;
  align-items: center;
}
</style>