<template>
  <div>
    <!-- 预付款 批量对账弹窗 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="25%">
      <div style="width: 100%; text-align: center">是否确认对账</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogVisible: false,
      arr: [],
    };
  },
  methods: {
    //   打开弹窗
    open(row) {
      row.forEach((item) => {
        this.arr.push(item.id);
      });
      this.dialogVisible = true;
    },
    // 确认
    sure() {
      this.$axios({
        method: "post",
        url:
          "/otaHotel/finance/advance/batchReconciliation?orderIds=" + this.arr,
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.getList();
        })
        .catch((error) => {});
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>