<template>
  <div>
    <!-- 查询列表 -->
    <div class="carryover">
      <el-form
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="城市">
          <el-input
            size="mini"
            style="width: 90px"
            clearable
            v-model="formInline.city"
            placeholder="城市名"
            prefix-icon="el-icon-location-outline"
          ></el-input>
        </el-form-item>
        <el-form-item label="酒店名称">
          <el-input
            size="mini"
            clearable
            v-model="formInline.writeHotelName"
            placeholder="酒店名称"
            style="width: 120px"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位">
          <el-select
            size="mini"
            v-model="formInline.job"
            @change="choiseGroup"
            style="width: 100px"
            placeholder="全部"
          >
            <el-option
              v-for="item in job"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别">
          <el-select
            size="mini"
            placeholder="全部"
            style="width: 100px"
            v-model="formInline.group"
          >
            <el-option
              v-for="item in group"
              :key="item.keyy"
              :label="item.valuee"
              :value="item.keyy"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员">
          <el-input
            size="mini"
            clearable
            style="width: 100px"
            v-model="formInline.people"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin: right">
          <el-button size="mini" type="primary" @click="search">搜索</el-button>
          <a
            :href="Url"
            @click="orderExport"
            v-show="role_finance_carryover_export"
            >导出</a
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 预付款酒店 -->
    <div class="fullTransfer">
      <div class="sec-box">
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @expand-change="handleSelectionChange"
       
        
      >
       <!-- :row-key="getRowKeys"
       :expand-row-keys="expands" -->
        <el-table-column label="城市/酒店名称">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.cityName">
                [{{ scope.row.cityName }}]
              </span>
              <span>{{ scope.row.hotelName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="payAmount" label="预付款金额"> </el-table-column>
        <el-table-column prop="totalAmount" label="总金额"> </el-table-column>
        <el-table-column prop="sales" label="销售"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <div class="cic" @click="modified(scope.row)">设置金额</div> -->
            <el-button size="mini" type="primary" plain  @click="modified(scope.row)">设置金额</el-button>
            <el-button size="mini" type="primary" plain  @click="openOnlineTransfer(scope.row)">线上转款</el-button>
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template  slot-scope="props">
            <div>
              <el-table
                :data="props.row.expandData"
                tooltip-effect="dark"
                style="width: 100%"
              >
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column
                  prop="handler"
                  label="处理人"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="操作时间"
                ></el-table-column>
                <el-table-column
                  prop="setAmount"
                  label="设置金额"
                ></el-table-column>
                <el-table-column
                  prop="amountChangeRecord"
                  label="操作记录"
                ></el-table-column>
              </el-table>
              <div class="sec-box">
                <!-- 分页 -->
                <el-pagination
                  @size-change="(val) => inlinehandleSizeChange(val, props.row)"
                  @current-change="(val) => inlinehandleCurrentChange(val, props.row)"
                  :current-page="props.row.currentPage"
                  :page-size="props.row.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout="total, sizes, prev, pager, next"
                  :total="props.row.total"
                >
                </el-pagination>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- <ModifiedAmount :getList="getList" ref="modified" />
    <TransferDiagLog :getList="getList" ref="transferDiaglog" />
    <BatchTransferDiagLog :getList="getList" ref="batch" /> -->
    <SetPrepayAmount ref="setprepayamount"></SetPrepayAmount>
    <OnlineTransferDia ref="onlinetransferdia"></OnlineTransferDia>
  </div>
</template>

<script>
import {
  getTransferOrderList,
  transferOrderExportExcel,
  getGroups,
  getPlatformList,
  getPrepaidHotel_api,
  getPrepaidHotelOperationRecords_api,
} from "../../../apis/Finance";
// import ModifiedAmount from "./ModifiedAmount.vue";
// import TransferDiagLog from "./TransferDiagLog";
// import BatchTransferDiagLog from "./BatchTransferDiagLog.vue";
import SetPrepayAmount from "./SetPrepayAmount.vue";
import OnlineTransferDia from "./OnlineTransferDia.vue";
import local from "@/utils/local.js";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: { SetPrepayAmount,OnlineTransferDia },
  data() {
    return {
      role_finance_carryover_export: getRoleKey(
        "role_finance_carryover_export"
      ),
      tableData: [],
      multipleSelection: [],
      currentPage: 1, //当前页
      inlinecurrentPage: 1,
      count: 15, //当前页条数
      total: 0, //总数
      inlinecount: 10,
      arr: [],
      formInline: {
        name: "",
        ordernum: "",
        city: "",
        writeHotelName: "",
        channel: "",
        vest: "",
        timeType: "",
        times: "",
        job: "",
        group: "",
        people: "",
      },

      // 职位
      job: [
        { value: "0", label: "产品" },
        { value: "1", label: "运营" },
      ],
      // 组别
      group: [],
      Url: "",
      role: "",
      token: "",
      intotal: {
        inlinetotal: 0,
      },

      inlineTableList: [],
      inlinehotelSonId: "",
      expands: [],
      getRowKeys(row) {
        return row.hotelSonId;
      },
    };
  },
  created() {
    this.getList();
    this.role = local.get("role");
    this.token = local.get("tk");
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 选择
    handleSelectionChange(row, expandedRows) {
      this.inlinecurrentPage = 1;
      this.inlinehotelSonId = row.hotelSonId;


       this.hotelSonId = row.hotelSonId;
        this.rowData = row;
      this.getTableInlineList(row.hotelSonId);

      // let that = this;
      // if (expandedRows.length) {
      //   that.expands = [];
      //   if (row) {
      //     that.expands.push(row.hotelSonId); // 每次push进去的是每行的ID
      //   }
      // } else {
      //   that.expands = []; // 默认不展开
      // }
    },

    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.currentPage = 1;
      this.getList();
    },
    inlinehandleSizeChange(val,row) {
      this.inlinecount = val;
      this.linecurrentPage = 1;
        this.rowData = row;
      this.rowData.pageSize = val;
      this.hotelSonId = row.hotelSonId;
      this.getTableInlineList(this.inlinehotelSonId);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    inlinehandleCurrentChange(val,row) {
      this.inlinecurrentPage = val;
       this.rowData = row;
      this.rowData.currentPage = val;
      this.hotelSonId = row.hotelSonId;
      this.getTableInlineList(this.inlinehotelSonId);
    },

    // 获取列表
    async getList() {
      let { code, data } = await getPrepaidHotel_api({
        pageNumber: this.currentPage,
        pageSize: this.count,
        guestContact: this.formInline.name,
        number: this.formInline.ordernum,
        cityName: this.formInline.city,
        hotelName: this.formInline.writeHotelName,

        roleName: this.formInline.job,
        groupId: this.formInline.group,
        salesPerson: this.formInline.people,
      });
      if (code == 0) {
        // console.log(data, "list");
        this.tableData = data.records;
         this.tableData.forEach((item) => {
          this.$set(item, "expandData", []);
          this.$set(item, "pageSize", 10);
          this.$set(item, "currentPage", 1);
          this.$set(item, "total", 0);
        });
        this.total = data.total;
      }
    },
      // 获取详细列表
    async getTableInlineList(hotelSonId) {
      this.inlineTableList = [];
        this.tableDataSon = [];
      let { code, data } = await getPrepaidHotelOperationRecords_api({
        hotelSonId: this.hotelSonId,
       pageNumber: this.rowData.currentPage,
        pageSize: this.rowData.pageSize,
      
      });
      if (code == 0) {
        data.records.forEach((item) => {
          this.inlineTableList.push(item);
           this.tableDataSon.push(item);
        });
          this.tableData.forEach((item) => {
          if (item.hotelSonId == this.hotelSonId) {
            this.$set(item, "total", data.total);
            item.expandData = this.tableDataSon;
          }
        });
       // this.$set(this.intotal, "inlinetotal", data.total);
        // this.intotal.inlinetotal = data.total;
      }
    },

    // 点击打开修改金额
    modified(row) {
      this.$refs.setprepayamount.open(row.hotelSonId);
    },
    // 点击打开转账
    // toTransfer(row) {
    //   this.$refs.transferDiaglog.open(row);
    // },
    // 批量对账
    // BatchReconciliation() {
    //   let arr = this.arr;
    //   this.$refs.batch.open(arr);
    // },
    // 获取马甲id
    async getVestList() {
      let { code, data } = await getPlatformList({});
      if (code == 0) {
        console.log(data, "majia");
        if (this.formInline.channel == "1") {
          this.horseVestId = data[0].vestList;
        } else if (this.formInline.channel == "2") {
          this.horseVestId = data[1].vestList;
        } else if (this.formInline.channel == "3") {
          this.horseVestId = data[2].vestList;
        } else if (this.formInline.channel == "4") {
          this.horseVestId = data[3].vestList;
        } else if (this.formInline.channel == "5") {
          this.horseVestId = data[4].vestList;
        } else {
          this.horseVestId = [];
        }
        console.log(this.horseVestId, "111111111111");
      }
    },
    // 选择渠道刷新马甲
    choisePlatform() {
      this.getVestList();
      if (this.formInline.channel != "") {
        this.formInline.vest = "";
      }
    },
    // 获取组别
    async getGroup() {
      let { code, data } = await getGroups({
        positionType: this.formInline.job,
      });
      if (code == 0) {
        this.group = data;
      }
    },
    // 选择职位刷新组别
    choiseGroup() {
      this.getGroup();
      if (this.formInline.job != "") {
        this.formInline.group = "";
      }
    },
    // 搜索
    search() {
      this.currentPage=1
      this.getList();
    },
    // 导出
    async orderExport() {
      this.Url =
        "http://47.108.228.93:8080/otaHotel/finance/transfer/transferOrderExportExcel?token=" +
        this.token +
        "&transferType=" +
        7 +
        "&pageNumber=" +
        this.currentPage +
        "&pageSize=" +
        this.count +
        "&guestContact=" +
        this.formInline.name +
        "&number=" +
        this.formInline.ordernum +
        "&city=" +
        this.formInline.city +
        "&hotelName=" +
        this.formInline.writeHotelName +
        "&platform=" +
        this.formInline.channel +
        "&horseVestId=" +
        this.formInline.vest +
        "&dateType=" +
        this.formInline.timeType +
        "&startDate=" +
        this.formInline.times[0] +
        "&endDate=" +
        this.formInline.times[1] +
        "&position=" +
        this.formInline.job +
        "&groupId=" +
        this.formInline.group +
        "&managerName=" +
        this.formInline.people;
    },
    //打开线上转款的模态框
    openOnlineTransfer(row){
         this.$refs.onlinetransferdia.open(row);
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-table {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.cic {
  color: #256ef1 !important;
  cursor: pointer;
}
.btnCC {
  width: 70px;
  height: 28px;
  line-height: 0;
  padding: 9px 10px;
  box-sizing: border-box;
}
.sec-box {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: right;
  .el-pagination {
    padding: 10px;
    box-sizing: border-box;
  }
}
/deep/.el-form {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  .el-form-item {
    margin: 0 10px;
  }
}
/deep/.el-form--inline .el-form-item__content {
  display: flex;
}
a {
  width: 56px;
  height: 29px;
  font-size: 12px;
  border-radius: 3px;
  color: rgb(37, 110, 241) !important;
  text-align: center;
  line-height: 29px;
  border: none;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgb(37, 110, 241);
}
</style>

