<template>
  <!-- 审核查询 -->
  <div>
    <div class="carryover">
      <el-form
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="客人姓名">
          <el-input
            size="small"
            clearable
            v-model="formInline.name"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input
            size="small"
            clearable
            v-model="formInline.ordernum"
            placeholder="渠道订单号或后四位"
          ></el-input>
        </el-form-item>
        <el-form-item label="城市">
          <el-input
            size="small"
            clearable
            v-model="formInline.city"
            placeholder="城市名"
            prefix-icon="el-icon-location-outline"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-select
            size="small"
            v-model="formInline.hotelName"
            placeholder="酒店名称"
            style="width: 110px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            size="small"
            clearable
            v-model="formInline.writeHotelName"
            placeholder=""
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道">
          <el-select
            size="small"
            v-model="formInline.channel"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select size="small" v-model="formInline.vest">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期选择">
          <el-select
            size="small"
            v-model="formInline.timeType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-date-picker
            size="small"
            v-model="formInline.times"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="职位">
          <el-select size="small" v-model="formInline.job">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别">
          <el-select size="small" v-model="formInline.group">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员">
          <el-input
            size="small"
            clearable
            v-model="formInline.people"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item style="margin: right">
          <el-button size="mini" type="primary">搜索</el-button>
          <el-button
            size="mini"
            type=""
            style="border: 1px solid #256ef1; color: #256ef1"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        name: "",
        ordernum: "",
        user: "",
        city: "",
        hotelName: "",
        writeHotelName: "",
        channel: "",
        vest: "",
        timeType: "",
        times: "",
        job: "",
        group: "",
        people: "",
      },
      //下拉框
      options: [
        {
          value: "选项1",
          label: "白班",
        },
        {
          value: "选项2",
          label: "夜班",
        },
        {
          value: "选项3",
          label: "凌晨班",
        },
      ],
      //日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  .el-form-item {
    margin: 0 10px;
  }
}
/deep/.el-form--inline .el-form-item__content {
  display: flex;
}
</style>