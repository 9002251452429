<template>
  <div>
    <el-dialog
      title="添加驳回备注"
      :visible.sync="dialogFormVisible"
      width="300px"
      :close-on-click-modal="false"
    >
      <el-input type="textarea" :rows="6" v-model.trim="upData.remarks"></el-input>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { transferReject_api } from "../../../apis/Finance";
export default {
  data() {
    return {
      upData: {
        orderIds: "",
        remarks: "",
      },

      dialogFormVisible: false,
    };
  },
  methods: {
    open(id) {
      this.upData.orderIds = id;
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      if (this.upData.remarks == "") {
        this.$message.warning("请填写备注");
      } else {
        let { code, data } = await transferReject_api(this.upData);
        if (code == 0) {
          this.$message.success(data ? data : "提交成功");
          this.dialogFormVisible = false;
          this.$parent.getList()
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>

