<template>
  <!-- 转账修改金额 -->
  <div>
    <el-dialog title="修改金额" :visible.sync="dialogVisible" width="30%">
      <div class="cont">
        <span>金额</span>
        <el-input v-model="input" placeholder="请输入内容"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="close">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogVisible: false,
      input: "",
      id: "",
    };
  },
  methods: {
    //
    open(row) {
      console.log(row, "2222222222");
      this.dialogVisible = true;
      this.id = row.id;
    },
    // 确定
    close() {
      this.$axios({
        method: "POST",
        url:
          "/otaHotel/finance/transfer/updateAmount?orderId=" +
          this.id +
          "&amount=" +
          Number(this.input),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          // orderId: this.id,
          // amount: Number(this.input),
        },
      })
        .then((res) => {
          console.log(res, "res");
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.input = "";
            this.getList();
          }
        })
        .catch((error) => {
          //   console.log(error);
        });
      this.dialogVisible = false;
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.cont {
  display: flex;
  align-items: center;
  padding: 0 100px;
  box-sizing: border-box;
  span {
    width: 50px;
    font-size: 16px;
    font-weight: 400;
    color: #333;
  }
}
</style>