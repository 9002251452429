<template>
  <!-- 退款管理表单 -->
  <div class="examineform">
    <div class="head">
      <!-- 按钮选项 -->
      <div class="btn">
        <el-button
          size="mini"
          :class="btnCur == 0 ? 'active' : ''"
          v-if="role_finance_refund_pending"
          @click="btnFn(0)"
          >待处理</el-button
        >
        <el-button
          size="mini"
          :class="btnCur == 1 ? 'active' : ''"
         v-if="role_finance_refund_refunded"
          @click="btnFn(1)"
          >已退款</el-button
        >
        <el-button
          size="mini"
          :class="btnCur == 2 ? 'active' : ''"
         v-if="role_finance_refund_baddebts"
          @click="btnFn(2)"
          >坏账</el-button
        >
        <el-button
          size="mini"
          :class="btnCur == 3 ? 'active' : ''"
         v-if="role_finance_refund_depositingmoney"
          @click="btnFn(3)"
          >酒店冲抵款</el-button
        >
      </div>
      <Pending v-if="btnCur == '0'" />
      <Refunded v-if="btnCur == '1'" />
      <BadDebt v-if="btnCur == '2'" />
      <HotelOffset v-if="btnCur == '3'" />
    </div>
  </div>
</template>

<script>
import Pending from "./Pending.vue";
import Refunded from "./Refunded.vue";
import BadDebt from "./BadDebt.vue";
import HotelOffset from "./HotelOffset.vue";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: { Pending, Refunded, BadDebt, HotelOffset },
  data() {
    return {
      role_finance_refund_pending:getRoleKey('role_finance_refund_pending'),
      role_finance_refund_refunded:getRoleKey('role_finance_refund_refunded'),
      role_finance_refund_baddebts:getRoleKey('role_finance_refund_baddebts'),
      role_finance_refund_depositingmoney:getRoleKey('role_finance_refund_depositingmoney'),
      btnCur: "0",
     
    };
  },
  methods: {
    // 选项卡点击事件
    btnFn(index) {
      this.btnCur = index;
    },
  },
};
</script>

<style lang="less" scoped>
.examineform {
  width: 100%;
  background: #fff;
  margin-top: 20px;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  padding: 12px 20px;
  box-sizing: border-box;
  .head {
    display: flex;
    flex-direction: column;
    .btn {
      padding: 10px;
      box-sizing: border-box;
      .el-button {
        border: 1px solid #256def;
      }
      .el-button.active {
        background: #256def;
        color: #fff;
      }
    }
  }
}
</style>

