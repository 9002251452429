<template>
  <!-- 报表 -->
  <div>
    <!-- <PublicForm /> -->
    <div class="btnCss">
      <!-- 按钮选项 -->
      <div class="title">
        <div class="btn">
          <el-button
            size="mini"
            v-if="role_finance_form_info"
            :class="btnCur == 0 ? 'active' : ''"
            @click="btnFn(0)"
          >订单明细</el-button>
          <el-button
            size="mini"
            v-if="role_finance_form_dayform"
            :class="btnCur == 1 ? 'active' : ''"
            @click="btnFn(1)"
          >日报表</el-button>
          <el-button
            size="mini"
            v-if="role_finance_form_monthform"
            :class="btnCur == 2 ? 'active' : ''"
            @click="btnFn(2)"
          >月报表</el-button>
        </div>
      </div>
      <OrderDetails v-if="btnCur == '0'" />
      <DailyReport v-if="btnCur == '1'" />
      <MonthlyReport v-if="btnCur == '2'" />
    </div>
  </div>
</template>

<script>
// import PublicForm from "./PublicForm";
import MonthlyReport from "./MonthlyReport.vue";
import DailyReport from "./DailyReport.vue";
import OrderDetails from "./OrderDetails.vue";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: { MonthlyReport, DailyReport, OrderDetails },
  data() {
    return {
      role_finance_form_info: getRoleKey("role_finance_form_info"),
      role_finance_form_dayform: getRoleKey("role_finance_form_dayform"),
      role_finance_form_monthform: getRoleKey("role_finance_form_monthform"),

      btnCur: "0",
     
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
    };
  },
  methods: {
    // 选项卡点击事件
    btnFn(index) {
      this.btnCur = index;
    },
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
    },
  },
};
</script>

<style lang="less" scoped>
.btnCss {
  width: 100%;
  background: #fff;
  margin-top: 20px;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  padding: 12px 20px;
  box-sizing: border-box;
}
.title {
  display: flex;
  justify-content: space-between;
}
.el-button {
  border: 1px solid #256def;
  color: #256def;
}
.el-button.active {
  background: #256def;
  color: #fff;
}
</style>