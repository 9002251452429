<template>
  <!-- 退款管理弹窗 -->
  <div>
    <el-dialog
      title="退款处理"
      :visible.sync="dialogFormVisible"
      width="28%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <div class="radios">
        <div class="text">处理状态</div>
        <el-radio-group v-model="radio" @change="changeVal">
          <el-radio :label="1">退款完成</el-radio>
          <el-radio :label="2">转至坏账</el-radio>
        </el-radio-group>
      </div>
      <div class="inputs">
        <div class="text">备注</div>
        <div class="inputSize">
          <el-input
            type="textarea"
            :rows="5"
            size="mini"
            autosize
            placeholder="填写备注内容"
            v-model="textarea"
          >
          </el-input>
        </div>
      </div>
      <div class="btn">
        <el-button size="small" type="primary" plain @click="close"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="hold">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      radio: 1,
      textarea: "",
      typeVal: 1,
      id: "",
    };
  },
  methods: {
    open(row) {
      this.id = row.id;
      this.dialogFormVisible = true;
    },
    // 确定退款
    async hold() {
      this.$axios({
        method: "post",
        url:
          "/otaHotel/finance/refund/process?orderId=" +
          this.id +
          "&processState=" +
          this.typeVal +
          "&remarks=" +
          this.textarea,
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
          }
        })
        .catch((error) => {});

      this.dialogFormVisible = false;
    },
    // 取消
    close() {
      this.dialogFormVisible = false;
    },
    // 单选值改变
    changeVal(value) {
      this.typeVal = value;
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding-top: 44px;
  box-sizing: border-box;
}
/deep/.el-dialog {
  border-radius: 20px;
}
.radios,
.inputs {
  display: flex;
  align-items: center;
  padding-left: 50px;
  box-sizing: border-box;
  .el-radio-group,
  .inputSize {
    padding-left: 20px;
    box-sizing: border-box;
  }
}
.inputs {
  padding-top: 25px;
  box-sizing: border-box;
}
.inputSize {
  width: 45%;
}
.text {
  width: 15%;
  text-align: right;
}
</style>