<template>
  <div>
    <!-- 查询列表 -->
    <div class="carryover">
      <el-form
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="职位">
          <el-select
            size="mini"
            v-model="formInline.job"
            @change="choiseGroup"
            placeholder="请选择职位"
            style="width: 150px"
          >
            <el-option
              v-for="item in job"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别">
          <el-select
            size="mini"
            style="width: 150px"
            placeholder="请选择组别"
            v-model="formInline.group"
          >
            <el-option
              v-for="item in group"
              :key="item.keyy"
              :label="item.valuee"
              :value="item.keyy"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品经理">
          <el-input
            size="mini"
            clearable
            v-model="formInline.pm"
            style="width: 150px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="发票抬头">
          <el-input
            size="mini"
            clearable
            style="width: 150px"
            v-model="formInline.invoice"
            placeholder="请输入发票抬头"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin: right">
          <el-button size="mini" type="primary" @click="search">搜索</el-button>
          <a
            :href="Url"
            size="mini"
            type=""
            style="border: 1px solid #256ef1; color: #256ef1"
            @click="orderExport"
            v-if="role_finance_examine_export"
            >导出</a
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 发票审核 -->
    <div>
      <div class="sec-box">
        <el-select
          size="mini"
          v-model="value"
          placeholder="请选择"
          style="width: 100px; height: 28px"
          @change="checkType"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%" @expand-change="open">
          <el-table-column prop="productManagerName" label="产品经理">
          </el-table-column>
          <el-table-column prop="transactionAmount" label="成交金额">
          </el-table-column>
          <el-table-column prop="invoiceAmount" label="开票金额">
          </el-table-column>
          <el-table-column prop="invoicedAmount" label="已开票金额">
          </el-table-column>
          <el-table-column prop="unInvoicedAmount" label="未开票金额">
          </el-table-column>

          <el-table-column  type="expand">
            <template slot-scope="props">
            <el-table :data="props.row.expandData" style="width: 100%">
              <el-table-column label="序号" type="index"> </el-table-column>
              <el-table-column prop="invoiceHeader" label="发票抬头">
              </el-table-column>
              <el-table-column prop="hotelName" label="酒店名称">
              </el-table-column>
              <el-table-column prop="invoiceAmount" label="开票金额">
              </el-table-column>
              <el-table-column prop="invoiceNo" label="发票号">
              </el-table-column>
              <el-table-column prop="billingDate" label="开票日期">
              </el-table-column>
              <el-table-column label="审核状态">
                <template slot-scope="scope">
                  <div>
                    {{
                      scope.row.auditStatus == 0
                        ? "待审核"
                        : scope.row.auditStatus == 1
                        ? "已通过"
                        : scope.row.auditStatus == 2
                        ? "驳回"
                        : ""
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="UninvoicedAmount" label="操作">
                <template slot-scope="scope">
                  <div
                    class="cic"
                    v-if="
                      scope.row.auditStatus == 0 ||
                      role.includes('caiwu_kuaiji') ||
                      role.includes('caiwu_chuna') ||
                      role.includes('caiwu_jl')
                    "
                  >
                    <span v-if="role_finance_examine_pass&&scope.row.auditStatus != 1" @click="toApproved(scope.row)">通过</span>&nbsp;&nbsp;
                    <span v-if="role_finance_examine_rejected&&scope.row.auditStatus != 1" @click="toReject(scope.row)">驳回</span>
                  </div>
                  <div
                    class="cic"
                    v-if="
                      scope.row.auditStatus == 2 &&role_finance_examine_passagain
                     
                    "
                    @click="resubmit(scope.row)"
                  >
                    重新提交
                  </div>
                </template>
              </el-table-column>
            </el-table>
           
             <el-pagination
              small
              layout="total, sizes,prev, pager, next,jumper"
              :total="props.row.total"
              :page-sizes="[10, 20, 30, 40, 50, 100]"
              :page-size="props.row.pageSize"
              :current-page="props.row.currentPage"
               @size-change="(val) => handleSizeChange1(val, props.row)"
              
              @current-change="(val) => handleCurrentChange1(val, props.row)"
            >
            </el-pagination>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <ApprovedDiaglog :getList="getList" ref="approve" />
    <Reject :getList="getList" ref="reject" />
    <Resubmit :getList="getList" ref="resubmit" />
  </div>
</template>

<script>
import {
  getInvoiceBaseInfoList,
  invoiceExportExcel,
  getGroups,
  getInvoiceDetailInfoList,
} from "../../../apis/Finance";
import ApprovedDiaglog from "./ApprovedDiaglog.vue";
import Reject from "./Reject.vue";
import Resubmit from "./Resubmit.vue";
//引入local
import local from "@/utils/local.js";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: { ApprovedDiaglog, Reject, Resubmit },
  data() {
    return {
       role_finance_examine_export:getRoleKey('role_finance_examine_export'),
       role_finance_examine_pass:getRoleKey('role_finance_examine_pass'),
       role_finance_examine_passagain:getRoleKey('role_finance_examine_passagain'),
       role_finance_examine_rejected:getRoleKey('role_finance_examine_rejected'),
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      currentPage1: 1, //当前页
      count1: 15, //当前页条数
      total1: 0, //总数
      options: [
        {
          value: "-1",
          label: "全部",
        },
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "已通过",
        },
        {
          value: "2",
          label: "驳回",
        },
      ],
      value: "",
      tableData: [],
      tableDataSon: [],
      formInline: {
        job: "",
        group: "",
        pm: "",
        invoice: "",
      },
      // 职位
      job: [
        { value: "0", label: "产品" },
        { value: "1", label: "运营" },
      ],
      // 组别
      group: [],
      auditStatus: -1,
    
      Url: "",
      token: "",
      role: "",
       rowData: "",
       productManagerId:'',
    };
  },
  methods: {
    //分页
    handleSizeChange(val) {
      this.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //详细数据分页
    handleSizeChange1(val) {
      this.rowData = row;
      this.rowData.pageSize = val;
      this.hotelSonId = row.hotelSonId;
      this.getDetailedList();
    },
    handleCurrentChange1(val) {
       this.rowData = row;
      this.rowData.currentPage = val;
      this.hotelSonId = row.hotelSonId;
      this.productManagerId = row.productManagerId;
      this.getDetailedList();
    },
    // 获取列表
    async getList() {
      let { code, data } = await getInvoiceBaseInfoList({
        pageNumber: this.currentPage,
        pageSize: this.count,
        groupId: this.formInline.group,
        managerName: this.formInline.pm,
      });
      if (code == 0) {
      //  console.log(data, "list111111111");
        this.tableData = data.records;
          this.tableData.forEach((item) => {
          this.$set(item, "expandData", []);
          this.$set(item, "pageSize", 10);
          this.$set(item, "currentPage", 1);
          this.$set(item, "total", 0);
        });
        this.total = data.total;
      }
    },
    // 获取详细列表
    async getDetailedList() {
      this.tableDataSon = [];
      let { code, data } = await getInvoiceDetailInfoList({
        auditStatus: this.auditStatus,
        productManagerId: this.productManagerId,
        pageNumber: this.rowData.currentPage,
        pageSize: this.rowData.pageSize,
        invoiceHeader: this.formInline.invoice,
      });
      if (code == 0) {
        // console.log(data);

        data.records.forEach((item) => {
          this.tableDataSon.push(item);
        });
         this.tableData.forEach((item) => {
          if (item.productManagerId == this.productManagerId) {
            this.$set(item, "total", data.total);
            item.expandData = this.tableDataSon;
          }
        });
        this.total1 = data.total;
      }
    },
    // 获取组别
    async getGroup() {
      let { code, data } = await getGroups({
        positionType: this.formInline.job,
      });
      if (code == 0) {
        this.group = data;
      }
    },
    // 选择职位刷新组别
    choiseGroup() {
      this.getGroup();
      if (this.formInline.job != "") {
        this.formInline.group = "";
      }
    },
    // 搜索
    search() {
      this.getList();
    },
    // 选择type
    checkType(val) {
      this.auditStatus = val;
      this.getDetailedList();
    },
    // 导出
    async orderExport() {
      this.Url =
        "http://47.108.228.93:8080/otaHotel/finance/examine/invoiceExportExcel?token=" +
        this.token +
        "&auditStatus=" +
        this.auditStatus +
        "&pageNumber=" +
        this.currentPage +
        "&pageSize=" +
        this.count +
        "&pageNumber2=" +
        this.currentPage1 +
        "&pageSize2=" +
        this.count1 +
        "&groupId=" +
        this.formInline.group +
        "&managerName=" +
        this.formInline.pm +
        "&invoiceHeader=" +
        "";
    },
    //
    open(row, expandedRows) {
        this.rowData = row;
      this.productManagerId = row.productManagerId;
      this.getDetailedList();
    },
    // 点击打开通过审核
    toApproved(row) {
      this.$refs.approve.open(row);
    },
    // 点击打开驳回
    toReject(row) {
      this.$refs.reject.open(row);
    },
    // 重新提交
    resubmit(row) {
      this.$refs.resubmit.open(row);
    },
  },
  created() {
    this.getList();
    this.token = local.get("tk");
    // console.log(this.token, "1231351355");
    this.role = local.get("role");
    console.log(this.role);
  },
};
</script>

<style lang="less" scoped>
.sec-box {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .el-pagination {
    padding: 10px;
    box-sizing: border-box;
  }
}
/deep/.el-table {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.cic {
  color: #256ef1 !important;
  cursor: pointer;
}
img {
  width: 72px;
  height: 38px;
}
a {
  width: 56px;
  height: 29px;
  font-size: 12px;
  border-radius: 3px;
  color: rgb(37, 110, 241) !important;
  text-align: center;
  line-height: 29px;
  border: none;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgb(37, 110, 241);
}
</style>