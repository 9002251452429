<template>
  <div>
    <!-- 查询列表 -->
    <div class="carryover">
      <el-form
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="客人姓名">
          <el-input
            size="mini"
            clearable
            v-model="formInline.name"
            placeholder="请输入人员姓名"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input
            size="mini"
            style="width: 160px"
            clearable
            v-model="formInline.ordernum"
            placeholder="渠道订单号或后四位"
          ></el-input>
        </el-form-item>
        <el-form-item label="城市">
          <el-input
            size="mini"
            style="width: 160px"
            clearable
            v-model="formInline.city"
            placeholder="城市名"
            prefix-icon="el-icon-location-outline"
          ></el-input>
        </el-form-item>
        <el-form-item label="酒店名称">
          <el-input
            size="mini"
            clearable
            v-model="formInline.writeHotelName"
            placeholder="请输入酒店名称"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道">
          <el-select
            size="mini"
            v-model="formInline.channel"
            placeholder="请选择渠道"
            @change="choisePlatform"
            style="width: 160px"
          >
            <el-option
              v-for="item in channel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="马甲">
          <el-select
            size="mini"
            placeholder="请选择马甲"
            v-model="formInline.vest"
            style="width: 160px"
          >
            <el-option
              v-for="item in horseVestId"
              :key="item.horseVestId"
              :label="item.horseVestName"
              :value="item.horseVestId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期类型">
          <el-select
            size="mini"
            v-model="formInline.timeType"
            placeholder="请选择日期"
            style="width: 160px"
          >
            <el-option
              v-for="item in timeType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="formInline.times"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="mini"
            style="width: 230px; margin-top: 4px"
          >
                    </el-date-picker
          >
        </el-form-item>
        <el-form-item label="职位">
          <el-select
            size="mini"
            placeholder="请选择职位"
            v-model="formInline.job"
            @change="choiseGroup"
            style="width: 160px"
          >
            <el-option
              v-for="item in job"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别">
          <el-select
            size="mini"
            placeholder="请选择组别"
            v-model="formInline.group"
            style="width: 160px"
          >
            <el-option
              v-for="item in group"
              :key="item.keyy"
              :label="item.valuee"
              :value="item.keyy"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员">
          <el-input
            size="mini"
            style="width: 160px"
            clearable
            v-model="formInline.people"
            placeholder="请选择人员"
          ></el-input>
        </el-form-item>
        <el-form-item label="发单状态">
          <el-select
            size="mini"
            placeholder="全部"
            v-model="formInline.status"
            style="width: 160px"
          >
            <el-option
              v-for="item in statusOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型">
          <el-select
            size="mini"
            placeholder="全部"
            v-model="formInline.orderType"
            style="width: 160px"
          >
            <el-option
              v-for="item in orderTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打款状态">
          <el-select
            size="mini"
            placeholder="全部"
            v-model="formInline.hotelPayState"
            style="width: 160px"
          >
            <el-option
              v-for="item in hotelPayStateOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否导出">
          <el-select
            size="mini"
            placeholder="全部"
            v-model="formInline.isExport"
            style="width: 160px"
          >
            <el-option
              v-for="item in isExportOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin: right" label="操作">
          <el-button size="mini" type="primary" @click="search">搜索</el-button>
          <a
            :href="Url"
            @click="orderExport"
            v-show="role_finance_carryover_export"
            >导出</a
          >
          <el-button
            size="mini"
            type="primary"
            style="margin-left: 10px"
            plain
            @click="clearSearchData"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 转款失败 -->
    <div class="transfer">
      <div class="sec-box">
        <div style="height: 48px; line-height: 48px">
          <el-button
            type="success"
            size="mini"
            class="btnCC"
            @click="BatchReconciliation"
            v-if="role_finance_carryover_transfers"
            >批量转款</el-button
          >
          <el-button
            type="info"
            size="mini"
            class="btnCC"
            @click="toTransferOfflines"
            >批量线下转</el-button
          >
          <el-button
            type="info"
            size="mini"
            class="btnCC"
            @click="toTransferRejects"
            >批量驳回</el-button
          >
        </div>

        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="订单号" width="120">
          <template slot-scope="scope">
            {{ scope.row.orderNum }}
            <div
              v-if="scope.row.orderType1 == 1"
              class="cuidanBox"
              style="background: #f7a12e"
            >
              催单
            </div>
            <div
              v-else-if="scope.row.orderType1 == 2"
              class="cuidanBox"
              style="background: #ee4d52"
            >
              交接单
            </div>
            <div
              v-else-if="scope.row.orderType1 == 3"
              class="cuidanBox"
              style="background: #38455d"
            >
              拦截订单
            </div>
            <div
              v-else-if="scope.row.orderType1 == 4"
              class="cuidanBox"
              style="background: #5473e8"
            >
              二次确认
            </div>
            <div
              v-else-if="scope.row.orderType1 == 5"
              class="cuidanBox"
              style="background: #ee4d52"
            >
              客诉订单
            </div>
            <div
              v-else-if="scope.row.orderType1 == 6"
              class="cuidanBox"
              style="background: #38455d"
            >
              服务申诉
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="hotelName" label="城市/酒店名称" width="120">
        </el-table-column>
        <el-table-column label="入离时间">
          <template slot-scope="scope">
            <div>{{ scope.row.checkIn }}</div>
            <div>{{ scope.row.checkOut }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="guestContact" label="入住人"> </el-table-column>
        <el-table-column label="渠道">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.platform == 1
                  ? "艺龙"
                  : scope.row.platform == 2
                  ? "飞猪"
                  : scope.row.platform == 3
                  ? "美团"
                  : scope.row.platform == 4
                  ? "去哪儿"
                  : "携程"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="thirdOrderNum" label="渠道订单号">
        </el-table-column>
        <el-table-column label="总底价/总采购价/业绩">
          <template slot-scope="scope">
            <div>{{ scope.row.totalAfterTax }}</div>
            <div style="color: #33b93f">{{ scope.row.purchasePrice }}</div>
            <div style="color: #ee4d52">{{ scope.row.profitPrice }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="returnAmount" label="待退金额" width="120">
        </el-table-column>
        <el-table-column label="实付金额">
          <template slot-scope="scope">
            <div>{{ scope.row.payAmount }}</div>
            <div
              class="cic"
              v-show="
                role_finance_carryover_editmoney && scope.row.isHotelPay != 2
              "
              @click="modified(scope.row)"
            >
              修改金额
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="productManager" label="产品经理">
        </el-table-column>
        <el-table-column prop="paymentName" label="收款人"> </el-table-column>
        <el-table-column label="支付类型">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.paymentType == 1
                  ? "支付宝"
                  : scope.row.paymentType == 2
                  ? "银行卡"
                  : scope.row.paymentType
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="paymentAccount" label="收款账户">
        </el-table-column>
        <el-table-column prop="openingBankName" label="收款账户开户行">
        </el-table-column>
        <el-table-column prop="isHotelPay" label="打款状态">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.isHotelPay == 0
                  ? "未打款"
                  : scope.row.isHotelPay == 1
                  ? "打款中"
                  : scope.row.isHotelPay == 2
                  ? "打款成功"
                  : scope.row.isHotelPay == 3
                  ? "打款失败"
                  : scope.row.isHotelPay == 4
                  ? "取消转款"
                  : ""
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="订单状态"
          prop="status"
          :formatter="formatStatus"
        ></el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <!-- <div
              class="cic"
              @click="toTransfer(scope.row)"
              v-if="
                role_finance_carryover_transfer &&
                scope.row.isHotelPay != 2 &&
                scope.row.payType != 1
              "
            >
              转款
            </div> -->
            <div v-if="scope.row.isHotelPay != 2 && scope.row.payType != 1">
              <div>
                <el-button
                  size="mini"
                  @click="toTransfer(scope.row)"
                  type="text"
                  v-if="role_finance_carryover_transfer"
                  >转款</el-button
                >
              </div>
              <div>
                <el-button
                  size="mini"
                  @click="toTransferOffline(scope.row.id)"
                  type="text"
                  >线下转</el-button
                >
              </div>
              <div>
                <el-button
                  v-if="scope.row.isHotelPay != 4"
                  size="mini"
                  @click="toTransferReject(scope.row.id)"
                  type="text"
                  >驳回</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="operation" type="expand">
          <template slot-scope="scope">
            <el-table :data="scope.row.orderLogList" style="width: 100%">
              <el-table-column label="序号" type="index"> </el-table-column>
              <el-table-column prop="managerName" label="处理人">
              </el-table-column>
              <el-table-column prop="operationTime" label="操作时间">
                <!-- <template slot-scope="scope">
                <div>{{ scope.row.times.timesYear }}</div>
                <div>{{ scope.row.times.timesHours }}</div>
              </template> -->
              </el-table-column>
              <el-table-column prop="remarks" label="备注"> </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>

      <FailDiaglog :getList="getList" ref="modified" />
    </div>
    <BatchTransferDiagLog :getList="getList" ref="batch" />
    <TransferDiagLog :getList="getList" ref="transferDiaglog" />
    <TransferOffline ref="transferoffline"></TransferOffline>
    <TransferReject ref="transferreject"></TransferReject>
  </div>
</template>

<script>
import {
  getTransferOrderList,
  transferOrderExportExcel,
  getGroups,
  getPlatformList,
} from "../../../apis/Finance";
import BatchTransferDiagLog from "./BatchTransferDiagLog.vue";
import FailDiaglog from "./FailDiaglog.vue";
import TransferDiagLog from "./TransferDiagLog.vue";
import TransferOffline from "./TransferOffline.vue";
import TransferReject from "./TransferReject.vue";
import local from "@/utils/local.js";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: {
    FailDiaglog,
    BatchTransferDiagLog,
    TransferDiagLog,
    TransferOffline,
    TransferReject,
  },
  data() {
    return {
      role_finance_carryover_export: getRoleKey(
        "role_finance_carryover_export"
      ),
      role_finance_carryover_editmoney: getRoleKey(
        "role_finance_carryover_editmoney"
      ),
      role_finance_carryover_transfer: getRoleKey(
        "role_finance_carryover_transfer"
      ),
      role_finance_carryover_transfers: getRoleKey(
        "role_finance_carryover_transfers"
      ),
      tableData: [],
      multipleSelection: [],
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      formInline: {
        name: "",
        ordernum: "",
        city: "",
        writeHotelName: "",
        channel: "",
        vest: "",
        timeType: "",
        times: "",
        job: "",
        group: "",
        people: "",
        status: "",
        orderType: "",
        hotelPayState: "",
        isExport: "",
      },
      // 渠道
      channel: [
        { value: "1", label: "艺龙" },
        { value: "2", label: "飞猪" },
        { value: "3", label: "美团" },
        { value: "4", label: "去哪儿" },
        { value: "5", label: "携程" },
      ],
      statusOption: [
        { label: "全部", value: "" },
        { label: "未处理", value: "0" },
        { label: "已确认", value: "1" },
        { label: "确认前取消中", value: "2" },
        { label: "确认后取消中", value: "3" },
        { label: "确认前取消", value: "4" },
        { label: "确认后取消", value: "5" },
        { label: "超时拒绝", value: "6" },
        { label: "正常拒绝", value: "7" },
        { label: "保留房确认中", value: "8" },
        { label: "订单超时", value: "9" },
        { label: "拒绝取消", value: "10" },
      ],
      orderTypeOption: [
        { label: "全部", value: "" },
        { label: "正常订单", value: "0" },
        { label: "催单", value: "1" },
        { label: "交接单", value: "2" },
        { label: "拦截订单", value: "3" },
        { label: "二次确认", value: "4" },
        { label: "客诉订单", value: "5" },
        { label: "服务申诉", value: "6" },
      ],
      hotelPayStateOption: [
        { label: "全部", value: "" },
        { label: "待转款", value: "0" },
        { label: "转款中", value: "1" },
        { label: "已转款", value: "2" },
        { label: "转款失败", value: "3" },
        { label: "拒绝转款", value: "4" },
      ],
       isExportOption:[
         { label: "全部", value: "" },
        { label: "未导出", value: "0" },
        { label: "已导出", value: "1" },
      ],
      // 马甲
      horseVestId: [],
      //时间
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 日期选择
      timeType: [
        { value: "1", label: "所有日期" },
        { value: "2", label: "下单日期" },
        { value: "3", label: "入住日期" },
        { value: "4", label: "离店日期" },
      ],
      // 职位
      job: [
        { value: "0", label: "产品" },
        { value: "1", label: "运营" },
      ],
      // 组别
      group: [],
      repStratTime: "",
      repEedTime: "",
      Url: "",
      arr: [],
      role: "",
      token: "",
    };
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.arr = val;
      this.multipleSelection = val;
    },
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 获取列表
    async getList() {
      if (this.formInline.times == null) {
        this.formInline.times = [];
        this.formInline.times[0] = "";
        this.formInline.times[1] = "";
        let { code, data } = await getTransferOrderList({
          transferType: 4,
          pageNumber: this.currentPage,
          pageSize: this.count,
          guestContact: this.formInline.name,
          number: this.formInline.ordernum,
          city: this.formInline.city,
          hotelName: this.formInline.writeHotelName,
          platform: this.formInline.channel,
          horseVestId: this.formInline.vest,
          dateType: this.formInline.timeType,
          startDate: this.formInline.times[0],
          endDate: this.formInline.times[1],
          position: this.formInline.job,
          groupId: this.formInline.group,
          managerName: this.formInline.people,
          status: this.formInline.status,
          orderType: this.formInline.orderType,
          hotelPayState: this.formInline.hotelPayState,
          isExport: this.formInline.isExport,
        });
        if (code == 0) {
          // console.log(data, "list");
          this.tableData = data.records;
          this.total = data.total;
        }
      } else {
        let { code, data } = await getTransferOrderList({
          transferType: 4,
          pageNumber: this.currentPage,
          pageSize: this.count,
          guestContact: this.formInline.name,
          number: this.formInline.ordernum,
          city: this.formInline.city,
          hotelName: this.formInline.writeHotelName,
          platform: this.formInline.channel,
          horseVestId: this.formInline.vest,
          dateType: this.formInline.timeType,
          startDate: this.formInline.times[0],
          endDate: this.formInline.times[1],
          position: this.formInline.job,
          groupId: this.formInline.group,
          managerName: this.formInline.people,
          status: this.formInline.status,
          orderType: this.formInline.orderType,
          hotelPayState: this.formInline.hotelPayState,
          isExport: this.formInline.isExport,
        });
        if (code == 0) {
          // console.log(data, "list");
          this.tableData = data.records;
          this.total = data.total;
        }
      }
    },
    // 点击打开修改金额
    modified(row) {
      console.log(row);
      this.$refs.modified.open(row);
    },
    // 获取马甲id
    async getVestList() {
      let { code, data } = await getPlatformList({});
      if (code == 0) {
        console.log(data, "majia");
        if (this.formInline.channel == "1") {
          this.horseVestId = data[0].vestList;
        } else if (this.formInline.channel == "2") {
          this.horseVestId = data[1].vestList;
        } else if (this.formInline.channel == "3") {
          this.horseVestId = data[2].vestList;
        } else if (this.formInline.channel == "4") {
          this.horseVestId = data[3].vestList;
        } else if (this.formInline.channel == "5") {
          this.horseVestId = data[4].vestList;
        } else {
          this.horseVestId = [];
        }
        console.log(this.horseVestId, "111111111111");
      }
    },
    // 选择渠道刷新马甲
    choisePlatform() {
      this.getVestList();
      if (this.formInline.channel != "") {
        this.formInline.vest = "";
      }
    },
    // 获取组别
    async getGroup() {
      let { code, data } = await getGroups({
        positionType: this.formInline.job,
      });
      if (code == 0) {
        this.group = data;
      }
    },
    // 选择职位刷新组别
    choiseGroup() {
      this.getGroup();
      if (this.formInline.job != "") {
        this.formInline.group = "";
      }
    },
    // 批量对账
    BatchReconciliation() {
      let arr = this.arr;
      this.$refs.batch.open(arr);
    },
    // 搜索
    search() {
      this.getList();
      // console.log(11111111);
      // console.log(this.formInline.times,'时间');
    },
    // 导出
    async orderExport() {
      this.Url =
        "http://47.108.228.93:8080/otaHotel/finance/transfer/transferOrderExportExcel?token=" +
        this.token +
        "&transferType=" +
        4 +
        "&pageNumber=" +
        this.currentPage +
        "&pageSize=" +
        this.count +
        "&guestContact=" +
        this.formInline.name +
        "&number=" +
        this.formInline.ordernum +
        "&city=" +
        this.formInline.city +
        "&hotelName=" +
        this.formInline.writeHotelName +
        "&platform=" +
        this.formInline.channel +
        "&horseVestId=" +
        this.formInline.vest +
        "&dateType=" +
        this.formInline.timeType +
        "&startDate=" +
        this.formInline.times[0] +
        "&endDate=" +
        this.formInline.times[1] +
        "&position=" +
        this.formInline.job +
        "&groupId=" +
        this.formInline.group +
        "&managerName=" +
        this.formInline.people +
        "&status=" +
        this.formInline.status +
        "&orderType=" +
        this.formInline.orderType +
        "&isExport=" +
        this.formInline.isExport +
        "&hotelPayState=" +
        this.formInline.hotelPayState;
    },
    // 点击打开转账
    toTransfer(row) {
      this.$refs.transferDiaglog.open(row);
    },
    formatStatus(row, column, cellValue) {
      if (cellValue == 0) {
        return "未处理";
      } else if (cellValue == 1) {
        return "已确认";
      } else if (cellValue == 2) {
        return "确认前取消中";
      } else if (cellValue == 3) {
        return "确认后取消中";
      } else if (cellValue == 4) {
        return "确认前取消";
      } else if (cellValue == 5) {
        return "确认后取消";
      } else if (cellValue == 6) {
        return "超时拒绝";
      } else if (cellValue == 7) {
        return "正常拒绝";
      } else if (cellValue == 8) {
        return "保留房确认中";
      } else if (cellValue == 9) {
        return "订单超时";
      } else if (cellValue == 10) {
        return "拒绝取消";
      }
    },
    clearSearchData() {
      this.formInline = {
        name: "",
        ordernum: "",
        city: "",
        writeHotelName: "",
        channel: "",
        vest: "",
        timeType: "",
        times: "",
        job: "",
        group: "",
        people: "",
        status: "",
        orderType: "",
        hotelPayState: "",
        isExport: "",
      };
    },

    toTransferOffline(id) {
      this.$refs.transferoffline.open(id);
    },
    toTransferOfflines() {
      if (this.arr.length == 0) {
        this.$alert("请勾选线下转账的数据");
      } else {
        let orderIds = [];
        let isHotelPays = [];
        this.arr.forEach((item) => {
          orderIds.push(item.id);
          isHotelPays.push(item.isHotelPay);
        });
        if (isHotelPays.indexOf(2) != "-1") {
          this.$alert("所选数据含有不能转为线下转账的数据，请核对");
        } else {
          orderIds = orderIds.join(",");
          this.$refs.transferoffline.open(orderIds);
        }
      }
    },
    toTransferReject(id) {
      this.$refs.transferreject.open(id);
    },
    toTransferRejects() {
      if (this.arr.length == 0) {
        this.$alert("请勾选线下转账的数据");
      } else {
        let orderIds = [];
        let isHotelPays = [];
        this.arr.forEach((item) => {
          orderIds.push(item.id);
          isHotelPays.push(item.isHotelPay);
        });
        if (isHotelPays.indexOf(2) != "-1") {
          this.$alert("所选数据含有不能驳回的数据，请核对");
        } else {
          orderIds = orderIds.join(",");
          this.$refs.transferreject.open(orderIds);
        }
      }
    },
  },
  created() {
    this.getList();
    this.role = local.get("role");
    this.token = local.get("tk");
  },
};
</script>

<style lang="less" scoped>
/deep/.el-table {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.cic {
  color: #256ef1 !important;
  cursor: pointer;
}
.btnCC {
  height: 28px;
  line-height: 0;
  padding: 9px 10px;
  box-sizing: border-box;
}
.sec-box {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .el-pagination {
    padding: 10px;
    box-sizing: border-box;
  }
}
/deep/.el-form {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  .el-form-item {
    margin: 0 10px;
  }
}
/deep/.el-form--inline .el-form-item__content {
  display: flex;
}
a {
  width: 56px;
  height: 29px;
  font-size: 12px;
  border-radius: 3px;
  color: rgb(37, 110, 241) !important;
  text-align: center;
  line-height: 29px;
  border: none;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgb(37, 110, 241);
}
.cuidanBox {
  padding: 0 8px;
  height: 18px;
  line-height: 18px;
  text-align: center;

  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}
</style>

