<template>
  <div>
    <!-- 收款记录弹窗 -->
    <el-dialog title="添加收款记录" :visible.sync="dialogVisible" width="40%">
      <div class="cont">
        <span>收款账户</span>
        <el-input
          v-model="account"
          placeholder="请输入内容"
          size="small"
        ></el-input>
      </div>
      <div class="cont">
        <span>金额</span>
        <el-input
          v-model="money"
          placeholder="请输入内容"
          size="small"
        ></el-input>
      </div>
      <div class="cont">
        <span>收款时间</span>
        <el-date-picker
          v-model="times"
          type="date"
          placeholder="选择日期"
          size="small"
          style="width: 100%"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="close">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogVisible: false,
      account: "",
      money: "",
      times: "",
      id: "",
    };
  },
  methods: {
    //   打开弹窗
    open(row) {
      //   console.log(row);
      this.id = row.id;
      this.dialogVisible = true;
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
    },
    // 确定
    close() {
      console.log(this.times);
      this.$axios({
        method: "post",
        url:
          "/otaHotel/finance/refund/addCollectionRecord?orderId=" +
          this.id +
          "&account=" +
          this.account +
          "&amount=" +
          this.money +
          "&collectionTime=" +
          this.times,
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      })
        .then((res) => {
          //   console.log(res);
          this.getList();
        })
        .catch((error) => {});
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.cont {
  display: flex;
  align-items: center;
  padding: 0 100px;
  box-sizing: border-box;
  margin-top: 20px;
  span {
    width: 100px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    text-align: right !important;
  }
  .el-input {
    margin-left: 20px;
  }
}
</style>