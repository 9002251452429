<template>
  <div>
    <!-- 查询列表 -->
    <div class="carryover">
      <el-form
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="客人姓名">
          <el-input
            size="mini"
            clearable
            v-model="formInline.name"
            placeholder="请输入客人姓名"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input
            size="mini"
            clearable
            v-model="formInline.ordernum"
            placeholder="渠道订单号或后四位"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="城市">
          <el-input
            size="mini"
            clearable
            v-model="formInline.city"
            placeholder="城市名"
            prefix-icon="el-icon-location-outline"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="酒店名称">
          <el-input
            size="mini"
            clearable
            v-model="formInline.writeHotelName"
            placeholder="酒店名称"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道">
          <el-select
            size="mini"
            v-model="formInline.channel"
            placeholder="请选择"
            @change="choisePlatform"
            style="width: 160px"
          >
            <el-option
              v-for="item in channel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="马甲">
          <el-select size="mini" v-model="formInline.vest" style="width: 160px">
            <el-option
              v-for="item in horseVestId"
              :key="item.horseVestId"
              :label="item.horseVestName"
              :value="item.horseVestId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期类型">
          <el-select
            size="mini"
            v-model="formInline.timeType"
            placeholder="请选择"
            style="width: 160px"
          >
            <el-option
              v-for="item in timeType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="formInline.times"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="mini"
            style="width: 230px; margin-top: 4px"
          >
                    </el-date-picker
          >
        </el-form-item>
        <el-form-item label="职位">
          <el-select
            size="mini"
            v-model="formInline.job"
            @change="choiseGroup"
            style="width: 160px"
          >
            <el-option
              v-for="item in job"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别">
          <el-select
            size="mini"
            v-model="formInline.group"
            style="width: 160px"
          >
            <el-option
              v-for="item in group"
              :key="item.keyy"
              :label="item.valuee"
              :value="item.keyy"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员">
          <el-input
            size="mini"
            clearable
            v-model="formInline.people"
            placeholder="请输入人员"
            style="width: 160px"
          ></el-input>
        </el-form-item>
         <el-form-item label="打款状态">
          <el-select
            size="mini"
            placeholder="全部"
            v-model="formInline.hotelPayState"
            style="width: 160px"
          >
            <el-option
              v-for="item in hotelPayStateOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="支付方式">
          <el-select
            size="mini"
            v-model="formInline.payType"
            style="width: 160px"
          >
            <el-option
              v-for="item in payTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin: right" label="操作">
          <el-button size="mini" type="primary" @click="search">搜索</el-button>
          <a :href="Url" @click="orderExport" v-if="role_finance_refund_export">导出</a>
        </el-form-item>
      </el-form>
    </div>
    <!-- 坏账 -->
    <div>
      <div class="sec-box">
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column label="订单号/城市/酒店名称">
          <template slot-scope="scope">
            <div>{{ scope.row.orderNum }}</div>
            <div>{{ scope.row.hotelName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="入离时间">
          <template slot-scope="scope">
            <div>{{ scope.row.checkIn }}</div>
            <div>{{ scope.row.checkOut }}</div>
            <!-- <div>{{ scope.row.times.day }}</div> -->
          </template>
        </el-table-column>
        <el-table-column prop="roomName" label="房型"> </el-table-column>
        <el-table-column prop="guestContact" label="入住人"> </el-table-column>
        <el-table-column label="分销方/渠道供应方">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.platform == 1
                  ? "艺龙"
                  : scope.row.platform == 2
                  ? "飞猪"
                  : scope.row.platform == 3
                  ? "美团"
                  : scope.row.platform == 4
                  ? "去哪儿"
                  : scope.row.platform == 1
                  ? "携程"
                  : ""
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="thirdOrderNum" label="渠道订单号">
        </el-table-column>
        <el-table-column prop="purchasePrice" label="总采购价">
        </el-table-column>
        <el-table-column prop="productManager" label="销售"> </el-table-column>
         <el-table-column prop="profitPrice" label="业绩"> </el-table-column>
        <el-table-column prop="offsetPrice" label="冲抵金额"> </el-table-column>
        <el-table-column prop="returnAmount" label="待退金额">
        </el-table-column>
         <el-table-column prop="isHotelPay" label="打款状态">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.isHotelPay == 0
                  ? "未打款"
                  : scope.row.isHotelPay == 1
                  ? "打款中"
                  : scope.row.isHotelPay == 2
                  ? "打款成功"
                  : scope.row.isHotelPay == 3
                  ? "打款失败"
                  : scope.row.isHotelPay == 4
                  ? "拒绝转款"
                  : ""
              }}
            </div>
          </template>
        </el-table-column>
         <el-table-column label="支付方式">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.payType == 0
                  ? "一单一结"
                  : scope.row.payType == 1
                  ? "预付款"
                  : scope.row.isHotelPay 
              }}
            </div>
          </template>
        </el-table-column>



        <el-table-column prop="badAmount" label="坏账金额"> </el-table-column>
        <el-table-column prop="remarks" label="备注"> </el-table-column>
        <el-table-column prop="remarks" label="状态">
          <template slot-scope="scope">
            <div>{{ scope.row.refundState == 2 ? "未处理" : "已处理" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="operation">
              <div
                class="add"
                @click="openDiaglog(scope.row)"
                v-if="scope.row.refundState == 2&&role_finance_refund_baddebtedit"
              >
                坏账处理
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table :data="scope.row.collectionRecords" style="width: 100%">
              <el-table-column prop="account" label="收款账号">
              </el-table-column>
              <el-table-column prop="amount" label="金额"> </el-table-column>
              <el-table-column prop="collectionTime" label="收款时间">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>

      <BadDebtDiagLog :getList="getList" ref="badDebt" />
    </div>
  </div>
</template>

<script>
import BadDebtDiagLog from "./BadDebtDiagLog.vue";
import {
  getRefundOrderList,
  getPlatformList,
  getGroups,
} from "../../../apis/Finance";
import local from "@/utils/local.js";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: { BadDebtDiagLog },
  data() {
    return {
      role_finance_refund_export:getRoleKey('role_finance_refund_export'),
      role_finance_refund_baddebtedit:getRoleKey('role_finance_refund_baddebtedit'),
      tableData: [],
      multipleSelection: [],
      tableDataSon: [],
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      value: "",
      formInline: {
        name: "",
        ordernum: "",
        city: "",
        writeHotelName: "",
        channel: "",
        vest: "",
        timeType: "",
        times: "",
        job: "",
        group: "",
        people: "",
         hotelPayState: "",
         payType:''
      },
      // 渠道
      channel: [
        { value: "1", label: "艺龙" },
        { value: "2", label: "飞猪" },
        { value: "3", label: "美团" },
        { value: "4", label: "去哪儿" },
        { value: "5", label: "携程" },
      ],
       hotelPayStateOption: [
        { label: "全部", value: "" },
        { label: "待转款", value: "0" },
        { label: "转款中", value: "1" },
        { label: "已转款", value: "2" },
        { label: "转款失败", value: "3" },
        { label: "拒绝转款", value: "4" },
      ],
       payTypeOption:[
        {label:"全部",value:""},
        {label:"一单一结",value:"0"},
        {label:"预付款",value:"1"},
      ],
      // 马甲
      horseVestId: [],
      //时间
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 日期选择
      timeType: [
        { value: "1", label: "所有日期" },
        { value: "2", label: "下单日期" },
        { value: "3", label: "入住日期" },
        { value: "4", label: "离店日期" },
      ],
      // 职位
      job: [
        { value: "0", label: "产品" },
        { value: "1", label: "运营" },
      ],
      // 组别
      group: [],
      Url: "",
      token: "",
    };
  },
  methods: {
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 全选反选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    openDiaglog(row) {
      this.$refs.badDebt.open(row);
    },
    // 获取列表
    async getList() {
      let { code, data } = await getRefundOrderList({
        refundType: 2,
        pageNumber: this.currentPage,
        pageSize: this.count,
        guestContact: this.formInline.name,
        number: this.formInline.ordernum,
        city: this.formInline.city,
        hotelName: this.formInline.writeHotelName,
        platform: this.formInline.channel,
        horseVestId: this.formInline.vest,
        dateType: this.formInline.timeType,
        startDate: this.formInline.times[0],
        endDate: this.formInline.times[1],
        position: this.formInline.job,
        groupId: this.formInline.group,
        managerName: this.formInline.people,
         hotelPayState: this.formInline.hotelPayState,
         payType: this.formInline.payType,
      });
      if (code == 0) {
        console.log(data, "list");
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    // 获取马甲id
    async getVestList() {
      let { code, data } = await getPlatformList({});
      if (code == 0) {
        console.log(data, "majia");
        if (this.formInline.channel == "1") {
          this.horseVestId = data[0].vestList;
        } else if (this.formInline.channel == "2") {
          this.horseVestId = data[1].vestList;
        } else if (this.formInline.channel == "3") {
          this.horseVestId = data[2].vestList;
        } else if (this.formInline.channel == "4") {
          this.horseVestId = data[3].vestList;
        } else if (this.formInline.channel == "5") {
          this.horseVestId = data[4].vestList;
        } else {
          this.horseVestId = [];
        }
        console.log(this.horseVestId, "111111111111");
      }
    },
    // 选择渠道刷新马甲
    choisePlatform() {
      this.getVestList();
      if (this.formInline.channel != "") {
        this.formInline.vest = "";
      }
    },
    // 获取组别
    async getGroup() {
      let { code, data } = await getGroups({
        positionType: this.formInline.job,
      });
      if (code == 0) {
        this.group = data;
      }
    },
    // 选择职位刷新组别
    choiseGroup() {
      this.getGroup();
      if (this.formInline.job != "") {
        this.formInline.group = "";
      }
    },
    // 搜索
    search() {
      this.getList();
    },
    // 导出
    async orderExport() {
      this.Url =
        "http://47.108.228.93:8080/otaHotel/finance/transfer/transferOrderExportExcel?token=" +
        this.token +
        "&transferType=" +
        2 +
        "&pageNumber=" +
        this.currentPage +
        "&pageSize=" +
        this.count +
        "&guestContact=" +
        this.formInline.name +
        "&number=" +
        this.formInline.ordernum +
        "&city=" +
        this.formInline.city +
        "&hotelName=" +
        this.formInline.writeHotelName +
        "&platform=" +
        this.formInline.channel +
        "&horseVestId=" +
        this.formInline.vest +
        "&dateType=" +
        this.formInline.timeType +
        "&startDate=" +
        this.formInline.times[0] +
        "&endDate=" +
        this.formInline.times[1] +
        "&position=" +
        this.formInline.job +
        "&groupId=" +
        this.formInline.group +
        "&managerName=" +
         this.formInline.people+
          "&payType=" +
        this.formInline.payType+
         "&hotelPayState=" +
        this.formInline.hotelPayState;
    },
  },
  created() {
    this.getList();
    this.token = local.get("tk");
  },
};
</script>

<style lang="less" scoped>
/deep/.el-table {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.operation {
  .back {
    width: 58px;
    height: 20px;
    background: #256def;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
  }
  .add {
    cursor: pointer;
    color: #256def;
  }
}
.sec-box {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  .el-pagination {
    padding: 10px;
    box-sizing: border-box;
  }
}
/deep/.el-table {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
/deep/.el-button--small,
.el-button--small.is-round {
  height: 28px;
  width: 70px;
  padding: 0;
}
/deep/.el-form {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  .el-form-item {
    margin: 0 10px;
  }
}
/deep/.el-form--inline .el-form-item__content {
  display: flex;
}
a {
  width: 56px;
  height: 29px;
  font-size: 12px;
  border-radius: 3px;
  color: rgb(37, 110, 241) !important;
  text-align: center;
  line-height: 29px;
  border: none;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgb(37, 110, 241);
}
</style>