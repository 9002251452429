<template>
  <div>
    <!-- 查询 -->
    <el-form
      :inline="true"
      label-width="auto"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item label="酒店名称">
        <el-input
          size="mini"
          clearable
          v-model="formInline.writeHotelName"
          placeholder="酒店名称"
          style="width: 140px"
        ></el-input>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="formInline.times"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          size="mini"
          style="width: 220px; margin-top: 4px"
        >
                  </el-date-picker
        >
      </el-form-item>
      <el-form-item label="组别">
        <el-select size="mini" v-model="formInline.group" style="width: 140px">
          <el-option
            v-for="item in options"
            :key="item.keyy"
            :label="item.valuee"
            :value="item.keyy"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品经理">
        <el-input
          size="mini"
          clearable
          v-model="formInline.people"
          placeholder="请输入产品经理"
          style="width: 140px"
        ></el-input>
      </el-form-item>
      <el-form-item label="对账状态">
        <el-select
          size="mini"
          placeholder="全部"
          v-model="formInline.isHotelPay"
          style="width: 140px"
        >
          <el-option
            v-for="item in hotelPayStateOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin: right">
        <el-button size="mini" type="primary" @click="search">搜索</el-button>
        <a :href="Url" @click="orderExport" v-if="role_finance_advance_export"
          >导出</a
        >
      </el-form-item>
    </el-form>
    <!-- 预付款表单 -->
    <div class="examineform">
      <div class="head">
        <div class="sec-box">
          <el-button
            type="success"
            v-if="role_finance_advance_reconciliations"
            size="small"
            @click="BatchReconciliation"
            >批量对账</el-button
          >
          <!-- 分页 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="count"
            :page-sizes="[15, 30, 45, 60]"
            layout="total, sizes, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%" @expand-change="open">
        <el-table-column label="城市/酒店名称" prop="hotelName">
        </el-table-column>
        <el-table-column label="产品经理" prop="productManagerName">
        </el-table-column>
        <el-table-column label="总金额" prop="totalAmount"> </el-table-column>
        <el-table-column label="使用金额" prop="usedAmount"> </el-table-column>
        <el-table-column label="余额" prop="balance"> </el-table-column>

        <el-table-column label="操作" type="expand">
          <template slot-scope="props">
            <el-table
              ref="multipleTable"
              :data="props.row.expandData"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection"> </el-table-column>
              <el-table-column label="订单号" prop="orderNum">
              </el-table-column>
              <el-table-column
                prop="roomName"
                label="房间/间数"
              ></el-table-column>
              <el-table-column label="入离时间">
                <template slot-scope="scope">
                  <div>{{ scope.row.checkIn }}</div>
                  <div>{{ scope.row.checkOut }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="guestContact"
                label="入住人"
              ></el-table-column>
              <el-table-column label="渠道">
                <template slot-scope="scope">
                  <div>
                    {{
                      scope.row.platform == 1
                        ? "艺龙"
                        : scope.row.platform == 2
                        ? "飞猪"
                        : scope.row.platform == 3
                        ? "美团"
                        : scope.row.platform == 4
                        ? "去哪儿"
                        : scope.row.platform == 5
                        ? "携程"
                        : ""
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalAfterTax"
                label="总底价"
              ></el-table-column>
              <el-table-column label="总采购价">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.currency }} {{ scope.row.purchasePrice }}
                  </div>
                  <div
                    class="cic"
                    v-if="role_finance_advance_editmoney"
                    @click="modified(scope.row)"
                  >
                    修改金额
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="profitPrice"
                label="业绩"
              ></el-table-column>
              <el-table-column prop="status" label="对账状态">
                <template slot-scope="scope">
                  <div>
                    {{
                      scope.row.isHotelPay == 0
                        ? "未对账"
                        : scope.row.isHotelPay == 2
                        ? "已对账"
                        : scope.row.isHotelPay == 4
                        ? "已驳回"
                        : ""
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div
                    v-if="
                      scope.row.isHotelPay == 0 &&
                      role_finance_advance_reconciliation
                    "
                    class="cic"
                    @click="toReconciliation(scope.row)"
                  >
                    对账
                  </div>
                  <div
                    v-if="scope.row.isHotelPay == 0"
                    class="cic"
                    @click="toRejected(scope.row)"
                  >
                    驳回
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->

            <el-pagination
              small
              layout="total, sizes,prev, pager, next,jumper"
              :total="props.row.total"
              :page-sizes="[10, 20, 30, 40, 50, 100]"
              :page-size="props.row.pageSize"
              :current-page="props.row.currentPage"
               @size-change="(val) => handleSizeChange1(val, props.row)"
              
              @current-change="(val) => handleCurrentChange1(val, props.row)"
            >
            </el-pagination>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <AdvanceModified :getList="getList" ref="modified" />
    <Reconciliation :getList="getList" ref="reconciliation" />
    <BatchReconciliationDiaglog :getList="getList" ref="batchReconciliation" />
  </div>
</template>

<script>
import {
  getAdvanceChargeBaseInfoList,
  getAdvanceChargeDetailInfoList,
  getGroups,
  toRejectedFn_api,
} from "../../../apis/Finance";
import AdvanceModified from "./AdvanceModified.vue";
import Reconciliation from "./Reconciliation.vue";
import BatchReconciliationDiaglog from "./BatchReconciliationDiaglog.vue";
import local from "@/utils/local.js";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: { AdvanceModified, Reconciliation, BatchReconciliationDiaglog },
  data() {
    return {
      role_finance_advance_export: getRoleKey("role_finance_advance_export"),
      role_finance_advance_reconciliation: getRoleKey(
        "role_finance_advance_reconciliation"
      ),
      role_finance_advance_reconciliations: getRoleKey(
        "role_finance_advance_reconciliations"
      ),
      role_finance_advance_editmoney: getRoleKey(
        "role_finance_advance_editmoney"
      ),
      currentPage: 1, //当前页
      currentPage1: 1, //当前页
      count: 15, //当前页条数
      count1: 10, //当前页条数
      total: 0, //总数
      total1: 0, //总数
      tableData: [],
      tableDataSon: [],
      formInline: {
        hotelName: "",
        writeHotelName: "",
        times: "",
        group: "",
        people: "",
        isHotelPay: "",
      },
      rowData: "",
      //下拉框
      options: [
        {
          value: "选项1",
          label: "白班",
        },
        {
          value: "选项2",
          label: "夜班",
        },
        {
          value: "选项3",
          label: "凌晨班",
        },
      ],
      //日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      multipleSelection: [],
      hotelSonId: "",
      productManagerId: "",
      Url: "",
      token: "",
      hotelPayStateOption: [
        { label: "全部", value: "" },
        { label: "未对账", value: "0" },

        { label: "已对账", value: "2" },

        { label: "已驳回", value: "4" },
      ],
    };
  },
  methods: {

    //分页
    handleSizeChange(val) {
      this.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
   
    handleCurrentChange1(val, row) {
     // console.log(row);
      this.rowData = row;
      this.rowData.currentPage = val;
      this.hotelSonId = row.hotelSonId;
      this.productManagerId = row.productManagerId;

      this.getDetailedList();
    },
    handleSizeChange1(val, row){
        this.rowData = row;
      this.rowData.pageSize = val;
      this.hotelSonId = row.hotelSonId;
       this.getDetailedList();
    },
    // 全选反选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取列表
    async getList() {
      let { code, data } = await getAdvanceChargeBaseInfoList({
        pageNumber: this.currentPage,
        pageSize: this.count,
        hotelName: this.formInline.writeHotelName,
        groupId: this.formInline.group,
        managerName: this.formInline.people,
      });
      if (code == 0) {
        // console.log(data, "data");
        this.tableData = data.records;
        this.tableData.forEach((item) => {
          this.$set(item, "expandData", []);
          this.$set(item, "pageSize", 10);
          this.$set(item, "currentPage", 1);
          this.$set(item, "total", 0);
        });
        this.total = data.total;
      }
    },
    // 获取详细列表
    async getDetailedList(row) {
      this.tableDataSon = [];
      let { code, data } = await getAdvanceChargeDetailInfoList({
        hotelSonId: this.hotelSonId,
        productManagerId: this.productManagerId,
        pageNumber: this.rowData.currentPage,
        pageSize: this.rowData.pageSize,
        startDate: this.formInline.times[0],
        endDate: this.formInline.times[1],
        isHotelPay: this.formInline.isHotelPay,
      });
      if (code == 0) {
        data.records.forEach((item) => {
          this.tableDataSon.push(item);
        });
        this.tableData.forEach((item) => {
          if (item.hotelSonId == this.hotelSonId) {
            this.$set(item, "total", data.total);
            item.expandData = this.tableDataSon;
          }
        });
        this.total1 = data.total;
      }
    },
    // 获取组别
    async getGroup() {
      let { code, data } = await getGroups({
        positionType: 0,
      });
      if (code == 0) {
        this.options = data;
      }
    },
    // 搜索
    search() {
      this.getList();
    },
    // 获取详情数据
    async open(row) {
      console.log(row);

      this.hotelSonId = row.hotelSonId;
      this.productManagerId = row.productManagerId;
      this.rowData = row;
      this.getDetailedList();
    },
    // 点击打开修改金额
    modified(row) {
      // console.log(row);
      this.$refs.modified.open(row);
    },
    // 点击打开对账弹窗
    toReconciliation(row) {
      this.$refs.reconciliation.open(row);
    },
    // 批量对账
    BatchReconciliation() {
      this.$refs.batchReconciliation.open(this.multipleSelection);
    },
    // 导出
    async orderExport() {
      this.Url =
        "http://47.108.228.93:8080/otaHotel/finance/advance/advanceChargeOrderExportExcel?token=" +
        this.token +
        "&pageNumber=" +
        this.currentPage +
        "&pageSize=" +
        this.count +
        "&pageNumber2=" +
        this.currentPage1 +
        "&pageSize2=" +
        this.count1 +
        "&hotelName=" +
        this.formInline.writeHotelName +
        "&groupId=" +
        this.formInline.group +
        "&managerName=" +
        this.formInline.people +
        "&startDate=" +
        this.formInline.times[0] +
        "&endDate=" +
        this.formInline.times[1];
    },
    //驳回
    toRejected(row) {
      this.$confirm("确定驳回吗？", "驳回确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.toRejectedFn(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消驳回",
          });
        });
    },
    async toRejectedFn(row) {
      let { code, data } = await toRejectedFn_api({
        orderId: row.id,
      });
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
      }
    },
  },
  created() {
    this.getList();

    this.getGroup();
    this.token = local.get("tk");
  },
};
</script>

<style lang="less" scoped>
.examineform {
  width: 100%;
  background: #fff;
  margin-top: 20px;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  padding: 12px 20px;
  box-sizing: border-box;
  .head {
    display: flex;
    flex-direction: column;
    .sec-box {
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
    /deep/.el-table {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }
  }
}
.cic {
  color: #256ef1 !important;
  cursor: pointer;
}
/deep/.el-button {
  width: 70px;
  // height: 28px;
  line-height: 0;
  padding: 9px 10px;
  box-sizing: border-box;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.cic {
  color: #256ef1 !important;
  cursor: pointer;
}
/deep/.el-table {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
/deep/.el-form {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  .el-form-item {
    margin: 0 10px;
  }
}
/deep/.el-form--inline .el-form-item__content {
  display: flex;
}
/deep/.el-form .el-form-item {
  display: flex;
  align-items: center;
}
a {
  width: 56px;
  height: 29px;
  font-size: 12px;
  border-radius: 3px;
  color: rgb(37, 110, 241) !important;
  text-align: center;
  line-height: 29px;
  border: none;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgb(37, 110, 241);
}
</style>