<template>
  <!-- 退款服务 -->
  <div>
    <!-- <PublicForm /> -->
    <RefundForm />
  </div>
</template>

<script>
import PublicForm from "./PublicForm";
import RefundForm from "./RefundForm.vue";
export default {
  components: { PublicForm, RefundForm },
};
</script>

<style lang="scss" scoped>
</style>