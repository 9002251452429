<template>
  <div>
    <!-- 查询列表 -->
    <div class="carryover">
      <el-form
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="客人姓名">
          <el-input
            size="mini"
            clearable
            v-model="formInline.name"
            placeholder="请输入客人姓名"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input
            size="mini"
            clearable
            v-model="formInline.ordernum"
            placeholder="渠道订单号或后四位"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="城市">
          <el-input
            size="mini"
            clearable
            v-model="formInline.city"
            placeholder="城市名"
            prefix-icon="el-icon-location-outline"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="酒店名称">
          <el-input
            size="mini"
            clearable
            v-model="formInline.writeHotelName"
            placeholder="酒店名称"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道">
          <el-select
            size="mini"
            v-model="formInline.channel"
            placeholder="请选择"
            @change="choisePlatform"
            style="width: 160px"
          >
            <el-option
              v-for="item in channel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="马甲">
          <el-select size="mini" v-model="formInline.vest" style="width: 160px">
            <el-option
              v-for="item in horseVestId"
              :key="item.horseVestId"
              :label="item.horseVestName"
              :value="item.horseVestId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期类型">
          <el-select
            size="mini"
            v-model="formInline.timeType"
            placeholder="请选择"
            style="width: 160px"
          >
            <el-option
              v-for="item in timeType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="formInline.times"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="mini"
            style="width: 230px; margin-top: 4px"
          >
                    </el-date-picker
          >
        </el-form-item>
        <el-form-item label="职位">
          <el-select
            size="mini"
            v-model="formInline.job"
            @change="choiseGroup"
            style="width: 160px"
          >
            <el-option
              v-for="item in job"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别">
          <el-select
            size="mini"
            v-model="formInline.group"
            style="width: 160px"
          >
            <el-option
              v-for="item in group"
              :key="item.keyy"
              :label="item.valuee"
              :value="item.keyy"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员">
          <el-input
            size="mini"
            clearable
            v-model="formInline.people"
            placeholder="请输入人员"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin: right" label="操作">
          <el-button size="mini" type="primary" @click="search">搜索</el-button>
          <a :href="Url" @click="out" v-if="role_finance_form_export">导出</a>
          <el-button
            size="mini"
            type="primary"
            style="margin-left: 10px"
            plain
            @click="clearSearchData"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 月报表 -->
    <div>
      <div class="sec-box">
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="groupName" label="组别"> </el-table-column>
          <el-table-column prop="productManager" label="产品经理">
          </el-table-column>
          <el-table-column prop="nightsCount" label="间夜量"> </el-table-column>
          <el-table-column prop="salesCount" label="销售量"> </el-table-column>
          <el-table-column prop="cost" label="底价"> </el-table-column>
          <el-table-column prop="profit" label="利润"> </el-table-column>
          <el-table-column prop="performanceTarget" label="目标任务">
          </el-table-column>
          <el-table-column prop="completionRate" label="实际完成率">
            <template slot-scope="scope">
              <div>{{ scope.row.completionRate * 100 }}%</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getReports,
  getPlatformList,
  getGroups,
  reportsExportExcel,
} from "../../../apis/Finance";
import local from "@/utils/local.js";
import { getRoleKey } from "@/utils/rolekey";
export default {
  data() {
    return {
      role_finance_form_export: getRoleKey("role_finance_form_export"),
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [],
      formInline: {
        name: "",
        ordernum: "",
        city: "",
        writeHotelName: "",
        channel: "",
        vest: "",
        timeType: "",
        times: "",
        job: "",
        group: "",
        people: "",
        status: "",
        orderType: "",
      },
      // 渠道
      channel: [
        { value: "1", label: "艺龙" },
        { value: "2", label: "飞猪" },
        { value: "3", label: "美团" },
        { value: "4", label: "去哪儿" },
        { value: "5", label: "携程" },
      ],
      // 马甲
      horseVestId: [],
      //时间
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 日期选择
      timeType: [
        { value: "1", label: "所有日期" },
        { value: "2", label: "下单日期" },
        { value: "3", label: "入住日期" },
        { value: "4", label: "离店日期" },
      ],
      // 职位
      job: [
        { value: "0", label: "产品" },
        { value: "1", label: "运营" },
      ],
      // 组别
      group: [],
      Url: "",
      token: "",
    };
  },
  methods: {
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 获取列表
    async getList() {
      let { code, data } = await getReports({
        reportType: 3,
        pageNumber: this.currentPage,
        pageSize: this.count,
        guestContact: this.formInline.name,
        number: this.formInline.ordernum,
        city: this.formInline.city,
        hotelName: this.formInline.writeHotelName,
        platform: this.formInline.channel,
        horseVestId: this.formInline.vest,
        dateType: this.formInline.timeType,
        startDate: this.formInline.times[0],
        endDate: this.formInline.times[1],
        position: this.formInline.job,
        groupId: this.formInline.group,
        managerName: this.formInline.people,
      });
      if (code == 0) {
        // console.log(data, "list111111111");
        this.tableData = data.monthlyReports.records;
        this.total = data.monthlyReports.total;
      }
    },
    // 获取马甲id
    async getVestList() {
      let { code, data } = await getPlatformList({});
      if (code == 0) {
        console.log(data, "majia");
        if (this.formInline.channel == "1") {
          this.horseVestId = data[0].vestList;
        } else if (this.formInline.channel == "2") {
          this.horseVestId = data[1].vestList;
        } else if (this.formInline.channel == "3") {
          this.horseVestId = data[2].vestList;
        } else if (this.formInline.channel == "4") {
          this.horseVestId = data[3].vestList;
        } else if (this.formInline.channel == "5") {
          this.horseVestId = data[4].vestList;
        } else {
          this.horseVestId = [];
        }
        console.log(this.horseVestId, "111111111111");
      }
    },
    // 选择渠道刷新马甲
    choisePlatform() {
      this.getVestList();
      if (this.formInline.channel != "") {
        this.formInline.vest = "";
      }
    },
    // 获取组别
    async getGroup() {
      let { code, data } = await getGroups({
        positionType: this.formInline.job,
      });
      if (code == 0) {
        this.group = data;
      }
    },
    // 选择职位刷新组别
    choiseGroup() {
      this.getGroup();
      if (this.formInline.job != "") {
        this.formInline.group = "";
      }
    },
    // 搜索
    search() {
      this.getList();
    },
    // 导出
    async out() {
      this.Url =
        "http://47.108.228.93:8080/otaHotel/finance/report/reportsExportExcel?token=" +
        this.token +
        "&reportType=3" +
        "&pageNumber=" +
        this.currentPage +
        "&pageSize=" +
        this.count +
        "&guestContact=" +
        this.formInline.name +
        "&number=" +
        this.formInline.ordernum +
        "&city=" +
        this.formInline.city +
        "&hotelName=" +
        this.formInline.writeHotelName +
        "&platform=" +
        this.formInline.channel +
        "&horseVestId=" +
        this.formInline.vest +
        "&dateType=" +
        this.formInline.timeType +
        "&startDate=" +
        this.formInline.times[0] +
        "&endDate=" +
        this.formInline.times[1] +
        "&position=" +
        this.formInline.job +
        "&groupId=" +
        this.formInline.group +
        "&managerName=" +
        this.formInline.people;
    },
    clearSearchData() {
      this.formInline = {
        name: "",
        ordernum: "",
        city: "",
        writeHotelName: "",
        channel: "",
        vest: "",
        timeType: "",
        times: "",
        job: "",
        group: "",
        people: "",
        status: "",
        orderType: "",
      };
    },
    getCurrentMonthFirst() {
      var date = new Date();

      date.setDate(1);

      var month = parseInt(date.getMonth() + 1);

      var day = date.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      return date.getFullYear() + "-" + month + "-" + day;
    },
     getCurrentMonthLast(){
var date=new Date();

var currentMonth=date.getMonth();

var nextMonth=++currentMonth;

var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);

var oneDay=1000*60*60*24;

var lastTime = new Date(nextMonthFirstDay-oneDay);

var month = parseInt(lastTime.getMonth()+1);

var day = lastTime.getDate();

if (month < 10) {
month = '0' + month

}

if (day < 10) {
day = '0' + day

}

return date.getFullYear() + '-' + month + '-' + day;

}
  },
  created() {
    this.formInline.times = [this.getCurrentMonthFirst(),this.getCurrentMonthLast()];
    this.getList();
    this.token = local.get("tk");
  },
};
</script>

<style lang="less" scoped>
/deep/.el-table {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.sec-box {
  display: flex;
  justify-content: flex-end;
}
/deep/.el-form {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  .el-form-item {
    margin: 0 10px;
  }
}
/deep/.el-form--inline .el-form-item__content {
  display: flex;
}
a {
  width: 56px;
  height: 29px;
  font-size: 12px;
  border-radius: 3px;
  color: rgb(37, 110, 241) !important;
  text-align: center;
  line-height: 29px;
  border: none;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgb(37, 110, 241);
}
</style>