<template>
  <!-- 查询列表 -->
  <div class="carryover">
    <el-form
      :inline="true"
      label-width="auto"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item label="客人姓名">
        <el-input
          size="small"
          clearable
          v-model="formInline.name"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input
          size="small"
          clearable
          v-model="formInline.ordernum"
          placeholder="渠道订单号或后四位"
        ></el-input>
      </el-form-item>
      <el-form-item label="城市">
        <el-input
          size="small"
          clearable
          v-model="formInline.city"
          placeholder="城市名"
          prefix-icon="el-icon-location-outline"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input
          size="small"
          clearable
          v-model="formInline.writeHotelName"
          placeholder="酒店名称"
          style="width: 160px"
        ></el-input>
      </el-form-item>
      <el-form-item label="渠道">
        <el-select
          size="small"
          v-model="formInline.channel"
          placeholder="请选择"
          @change="choisePlatform"
        >
          <el-option
            v-for="item in channel"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select size="small" v-model="formInline.vest">
          <el-option
            v-for="item in horseVestId"
            :key="item.horseVestId"
            :label="item.horseVestName"
            :value="item.horseVestId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期选择">
        <el-select
          size="small"
          v-model="formInline.timeType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in timeType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          size="small"
          v-model="formInline.times"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="职位">
        <el-select size="small" v-model="formInline.job" @change="choiseGroup">
          <el-option
            v-for="item in job"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="组别">
        <el-select size="small" v-model="formInline.group">
          <el-option
            v-for="item in group"
            :key="item.keyy"
            :label="item.valuee"
            :value="item.keyy"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="人员">
        <el-input
          size="small"
          clearable
          v-model="formInline.people"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item style="margin: right">
        <el-button size="mini" type="primary" @click="search">搜索</el-button>
        <el-button
          size="mini"
          type=""
          style="border: 1px solid #256ef1; color: #256ef1"
          >导出</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getPlatformList, getGroups } from "../../../apis/Finance";
export default {
  data() {
    return {
      formInline: {
        name: "",
        ordernum: "",
        city: "",
        writeHotelName: "",
        channel: "",
        vest: "",
        timeType: "",
        times: "",
        job: "",
        group: "",
        people: "",
      },
      // 渠道
      channel: [
        { value: "1", label: "艺龙" },
        { value: "2", label: "飞猪" },
        { value: "3", label: "美团" },
        { value: "4", label: "去哪儿" },
        { value: "5", label: "携程" },
      ],
      // 马甲
      horseVestId: [],
      //时间
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 日期选择
      timeType: [
        { value: "1", label: "所有日期" },
        { value: "2", label: "下单日期" },
        { value: "3", label: "入住日期" },
        { value: "4", label: "离店日期" },
      ],
      // 职位
      job: [
        { value: "0", label: "产品" },
        { value: "1", label: "运营" },
      ],
      // 组别
      group: [],
    };
  },
  methods: {
    // 获取马甲id
    async getVestList() {
      let { code, data } = await getPlatformList({});
      if (code == 0) {
        console.log(data, "majia");
        if (this.formInline.channel == "1") {
          this.horseVestId = data[0].vestList;
        } else if (this.formInline.channel == "2") {
          this.horseVestId = data[1].vestList;
        } else if (this.formInline.channel == "3") {
          this.horseVestId = data[2].vestList;
        } else if (this.formInline.channel == "4") {
          this.horseVestId = data[3].vestList;
        } else if (this.formInline.channel == "5") {
          this.horseVestId = data[4].vestList;
        } else {
          this.horseVestId = [];
        }
        console.log(this.horseVestId, "111111111111");
      }
    },
    // 选择渠道刷新马甲
    choisePlatform() {
      this.getVestList();
      if (this.formInline.channel != "") {
        this.formInline.vest = "";
      }
    },
    // 获取组别
    async getGroup() {
      let { code, data } = await getGroups({
        positionType: this.formInline.job,
      });
      if (code == 0) {
        this.group = data;
      }
    },
    // 选择职位刷新组别
    choiseGroup() {
      this.getGroup();
      if (this.formInline.job != "") {
        this.formInline.group = "";
      }
    },
    // 搜索
    search() {
      // this.getService();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  .el-form-item {
    margin: 0 10px;
  }
}
/deep/.el-form--inline .el-form-item__content {
  display: flex;
}
</style>