<template>
  <div>
    <el-dialog
      title="线下转"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >

     <el-select size="small" style="margin-bottom:20px;" v-model="upData.accountId" placeholder="请选择">
    <el-option
      v-for="item in offlinePayerAccountList"
      :key="item.id"
      :label="item.accountName"
      :value="item.id">
    </el-option>
  </el-select>
   
   
      <el-input type="textarea" :rows="6" v-model.trim="upData.remarks"></el-input>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { offlineTurn_api,getOfflinePayerAccountList_api } from "../../../apis/Finance";
export default {
  data() {
    return {
      upData: {
        orderId: "",
        remarks: "",
        accountId:'',
      },
      offlinePayerAccountList:[],

      dialogFormVisible: false,
    };
  },
  methods: {
    open(id) {
     this.getOfflinePayerAccountList()
      this.upData.orderId = id;
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      if (this.upData.accountId == "") {
        this.$message.warning("请选择账号");
      } else {
        let { code, data } = await offlineTurn_api(this.upData);
        if (code == 0) {
          this.$message.success(data ? data : "提交成功");
          this.dialogFormVisible = false;
          this.$parent.getList()
        }
      }
    },
    async getOfflinePayerAccountList(){
      let {code,data}= await getOfflinePayerAccountList_api()
      if(code==0){
         this.offlinePayerAccountList=data
      }
    }
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>

