<template>
  <div class="examineform">
    <div class="head">
      <!-- 按钮选项 -->
      <!-- <div class="btn"> -->
      <el-button
        size="mini"
        :class="btnCur == index ? 'active' : ''"
        v-for="(item, index) in btn"
        :key="index"
        @click="btnFn(index)"
        >{{ item.name }}</el-button
      >
      <!-- </div> -->
    </div>
    <FullTransfer v-if="btnCur == '0'" />
    <TransferredToday v-if="btnCur == '1'" />
    <ExceptionHandling v-if="btnCur == '2'" />
    <TransferFailed v-if="btnCur == '3'" />
    <TransferSucceeded v-if="btnCur == '4'" />
    <ManualOrder v-if="btnCur == '5'" />
    <AdvanceCharge v-if="btnCur == '6'" />
    <AdvanceTransfer  v-if="btnCur == '7'"></AdvanceTransfer>
    <OfflineTurn  v-if="btnCur == '8'"></OfflineTurn>
    
    
  </div>
</template>

<script>
import FullTransfer from "./FullTransfer.vue";
import ExceptionHandling from "./ExceptionHandling.vue";
import TransferFailed from "./TransferFailed.vue";
import TransferSucceeded from "./TransferSucceeded.vue";
import ManualOrder from "./ManualOrder.vue";
import TransferredToday from "./TransferredToday.vue";
import AdvanceCharge from "./AdvanceCharge.vue";
import AdvanceTransfer from "./AdvanceTransfer.vue";
import OfflineTurn from "./OfflineTurn.vue";
export default {
  components: {
    FullTransfer,
    ExceptionHandling,
    TransferFailed,
    TransferSucceeded,
    ManualOrder,
    TransferredToday,
    AdvanceCharge,
    AdvanceTransfer,
    OfflineTurn,
  },
  data() {
    return {
      btnCur: "0",
      btn: [
        { name: "全部转款" },
        { name: "今日待转" },
        { name: "异常待转" },
        { name: "转款失败" },
        { name: "转款成功" },
        { name: "手工单" },
        { name: "预付款酒店" },
        { name: "提前转款" },
        { name: "线下待转" },
      ],
    };
  },
  methods: {
    // 选项卡点击事件
    btnFn(index) {
      this.btnCur = index;
    },
  },
};
</script>

<style lang="less" scoped>
.examineform {
  width: 100%;
  background: #fff;
  margin-top: 20px;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  padding: 12px 20px;
  box-sizing: border-box;
}
.el-button {
  border: 1px solid #256def;
  color: #256ef1;
}
.el-button.active {
  background: #256def;
  color: #fff;
}
</style> 