<template>
  <!--  财务管理 -->
  <div class="finance">
    <!-- 服务分类 -->
    <div class="choose">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        active-text-color="#256DEF"
      >
        <el-menu-item index="1" class="menu-title" v-show="role_finance_carryover">我要转账</el-menu-item>
        <el-menu-item index="2" class="menu-title" v-show="role_finance_examine">审核</el-menu-item>
        <el-menu-item index="3" class="menu-title" v-show="role_finance_advance">预付款</el-menu-item>
        <el-menu-item index="4" class="menu-title" v-show="role_finance_refund">退款管理</el-menu-item>
        <el-menu-item index="5" class="menu-title" v-show="role_finance_form">报表</el-menu-item>
      </el-menu>
    </div>
    
    <!-- 对应页面 -->
    <Carryover v-if="index == '1'" />
    <Examine v-if="index == '2'" />
    <Advance v-if="index == '3'" />
    <Refund v-if="index == '4'" />
    <Form v-if="index == '5'" />
  </div>
</template>

<script>
import Advance from "../../components/dialog/finance/AdvanceFinance.vue";
import Carryover from "../../components/dialog/finance/CarryoverFinance.vue";
import Examine from "../../components/dialog/finance/ExamineFinance.vue";
import Form from "../../components/dialog/finance/FormFinance.vue";
import Refund from "../../components/dialog/finance/RefundFinance.vue";
import {getRoleKey} from "../../utils/rolekey"
export default {
  components: { Advance, Carryover, Examine, Form, Refund },
  data() {
    return {
       role_finance_carryover:getRoleKey('role_finance_carryover'),
       role_finance_examine:getRoleKey('role_finance_examine'),
       role_finance_advance:getRoleKey('role_finance_advance'),
       role_finance_refund:getRoleKey('role_finance_refund'),
       role_finance_form:getRoleKey('role_finance_form'),
      activeIndex: "1", //管理分类
      index: "1", //管理分类与组件对应展示的方式
    };
  },
  methods: {
    //服务分类
    handleSelect(key, keyPath) {
    //  console.log(key, keyPath);
      this.index = key;
    },
  },
};
</script>

<style lang="less" scoped>
.finance {
  .choose {
    width: 100%;
    height: 60px;
    background: #fff;
    margin-bottom: 20px;
    .el-menu {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #333;
      box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
      .menu-title {
        margin-left: 50px;
      }
    }
  }
}
</style>