<template>
  <!-- 坏账管理弹窗 -->
  <div>
    <el-dialog
      title="坏账处理"
      :visible.sync="dialogFormVisible"
      width="500px"
      top="10rem"
      :close-on-click-modal="false"
    >
      <div class="radios">
        <div class="text">处理状态</div>
        <el-radio-group v-model="radio" @change="changeVal">
          <el-radio :label="1">扣业绩</el-radio>
          <el-radio :label="2">线下处理</el-radio>
        </el-radio-group>
        
      </div>
      <div v-if="radio==1" style="margin-top:20px;" class="radios"><div class="text">扣除金额</div> <el-input size="mini" v-model="amount" style="width:180px;margin-left:20px;"></el-input> </div>
      <div class="btn">
        <el-button size="small" type="primary" plain @click="close"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="hold">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      radio: 1,
      id: "",
      typeVal: 1,
      amount:'',
    };
  },
  methods: {
    open(row) {
      console.log(row);
      this.id = row.id;
      this.dialogFormVisible = true;
    },
    // 确定
    async hold() {
      this.$axios({
        method: "post",
        url:
          "/otaHotel/finance/refund/badDebtProcess?orderId=" +
          this.id +
          "&processState=" +
          this.typeVal+
           "&amount=" +this.amount,
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.getList();
          }
        })
        .catch((error) => {});
      this.dialogFormVisible = false;
    },
    // 取消
    close() {
      this.dialogFormVisible = false;
    },
    changeVal(value) {
      this.typeVal = value;
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding-top: 46px;
  box-sizing: border-box;
}
/deep/.el-dialog {
  border-radius: 20px;
}
.radios,
.inputs {
  display: flex;
  align-items: center;
  padding-left: 50px;
  box-sizing: border-box;
  .el-radio-group,
  .inputSize {
    padding-left: 20px;
    box-sizing: border-box;
  }
}
.inputs {
  padding-top: 25px;
  box-sizing: border-box;
}
.inputSize {
  width: 45%;
}
.text {
  width: 15%;
  text-align: right;
}
</style>