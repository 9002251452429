<template>
  <!-- 上传发票弹窗 -->
  <div>
    <el-dialog
      title="上传发票"
      :visible.sync="dialogFormVisible"
      width="600px"
      top="10rem"
      :close-on-click-modal="false"
    >
      <div class="bigBox">
        <span>发票抬头</span>
        <el-select
          v-model="value"
          placeholder="请选择发票抬头"
          size="mini"
          @change="choiseHeader"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="bigBox">
        <span>酒店名称</span>
        <el-select
          v-model="hotelName"
          placeholder="请选择酒店名称"
          size="mini"
          @change="changeHotelName"
        >
          <el-option
            v-for="item in hotelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="bigBox">
        <span>开票金额</span>
        <el-input
          v-model="money"
          size="mini"
          placeholder="请输入开票金额"
        ></el-input>
      </div>
      <div class="bigBox">
        <span>发票号</span>
        <el-input
          v-model="num"
          size="mini"
          placeholder="请输入开票金额"
        ></el-input>
      </div>
      <div class="bigBox">
        <span>开票日期</span>
        <el-date-picker
          v-model="value1"
          size="mini"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="btn">
        <el-button size="small" type="primary" plain @click="close"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="sure">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getHotelList } from "../../../apis/Finance";
import { getHeaderList } from "../../../apis/Finance";
export default {
  data() {
    return {
      dialogFormVisible: false,
      options: [],
      value: "",
      money: "",
      num: "",
      value1: "",
      hotelName:"",
      hotelOptions:[],
    };
  },
  methods: {
    open() {
       this.getHotelName();
      this.getHeaderList();
      this.dialogFormVisible = true;
     
    },
    close() {
      this.dialogFormVisible = false;
    },
    // 保存
    sure() {
      this.$axios({
        method: "post",
        url:
          "/otaHotel/finance/examine/saveInvoiceInfo?invoiceHeader=" +
          String(this.value) +
          "&invoiceAmount=" +
          this.money +
          "&invoiceNo=" +
          this.num +
          "&billingDate=" +
          this.value1 +
          "&hotelName=" +
          this.hotelName+
          "&invoiceId=" +
          "",
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      })
        .then((res) => {})
        .catch((error) => {});
      this.dialogFormVisible = false;
    },
    // 获取酒店列表
    async getHotelName() {
      let { code, data } = await getHotelList({});
      if (code == 0) {
         console.log(data, "酒店");
        if (this.hotelOptions.length == 0) {
          data.forEach((item, index) => {
            this.hotelOptions.push({
              value: index,
              label: item,
            });
          });
        }
      }
    },
   // 或者发票抬头
    async getHeaderList() {
      let { code, data } = await getHeaderList({});
      if (code == 0) {
        // console.log(data, "发票抬头");
        if (this.options.length == 0) {
          data.forEach((item, index) => {
            this.options.push({
              value: index,
              label: item,
            });
          });
        }
      }
    },

    // 选择发票抬头
    choiseHeader(val) {
      console.log(val);
    },
    changeHotelName(){
      
    }
   
  },
  created() {},
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px;
}
.bigBox {
  display: flex;
  text-align: right;
  margin-bottom: 30px;
  align-items: center;
  span {
    width: 100px;
  }
  .el-input,
  .el-select {
    width: 70%;
    margin-left: 12px;
  }
}
.btn {
  //   width: 90%;
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  box-sizing: border-box;
}
</style>