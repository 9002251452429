<template>
  <div>
    <!-- 查询列表 -->
    <div class="carryover">
      <el-form
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="客人姓名">
          <el-input
            size="mini"
            clearable
            v-model="formInline.name"
            placeholder="请输入客人姓名"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input
            size="mini"
            clearable
            v-model="formInline.ordernum"
            style="width: 160px"
            placeholder="渠道订单号或后四位"
          ></el-input>
        </el-form-item>
        <el-form-item label="城市">
          <el-input
            size="mini"
            clearable
            v-model="formInline.city"
            style="width: 160px"
            placeholder="城市名"
            prefix-icon="el-icon-location-outline"
          ></el-input>
        </el-form-item>
        <el-form-item label="酒店名称">
          <el-input
            size="mini"
            clearable
            v-model="formInline.writeHotelName"
            placeholder="请输入酒店名称"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道">
          <el-select
            size="mini"
            v-model="formInline.channel"
            placeholder="请选择渠道"
            @change="choisePlatform"
            style="width: 160px"
          >
            <el-option
              v-for="item in channel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="马甲">
          <el-select
            size="mini"
            style="width: 160px"
            placeholder="请选择马甲"
            v-model="formInline.vest"
          >
            <el-option
              v-for="item in horseVestId"
              :key="item.horseVestId"
              :label="item.horseVestName"
              :value="item.horseVestId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期类型">
          <el-select
            size="mini"
            v-model="formInline.timeType"
            placeholder="请选择日期"
            style="width: 160px"
          >
            <el-option
              v-for="item in timeType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="formInline.times"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="mini"
            style="width: 230px; margin-top: 4px"
          >
                    </el-date-picker
          >
        </el-form-item>
        <el-form-item label="职位">
          <el-select
            size="mini"
            placeholder="请选择职位"
            v-model="formInline.job"
            @change="choiseGroup"
            style="width: 160px"
          >
            <el-option
              v-for="item in job"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别">
          <el-select
            size="mini"
            placeholder="请选择组别"
            style="width: 160px"
            v-model="formInline.group"
          >
            <el-option
              v-for="item in group"
              :key="item.keyy"
              :label="item.valuee"
              :value="item.keyy"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员">
          <el-input
            style="width: 160px"
            size="mini"
            clearable
            v-model="formInline.people"
            placeholder="请输入人员"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin: right" label="操作">
          <el-button size="mini" type="primary" @click="search">搜索</el-button>
          <a :href="Url" @click="orderExport" v-if="role_finance_examine_export"
            >导出</a
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 回执审核 -->
    <div>
      <div class="sec-box">
        <div style="height: 48px; line-height: 48px">
          <el-select
            size="mini"
            v-model="value"
            placeholder="请选择"
            style="width: 100px; height: 28px"
            @change="checkType"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            size="mini"
            style="margin-left: 10px"
            type="info"
            @click="toTongguos"
            >批量通过</el-button
          >
          <el-button size="mini" type="info" @click="toBohuis"
            >批量驳回</el-button
          >
        </div>

        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <div>
        <el-table
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="订单号/城市/酒店名称">
            <template slot-scope="scope">
              <div>{{ scope.row.orderNum }}</div>
              <div>{{ scope.row.hotelName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="入离时间">
            <template slot-scope="scope">
              <div>{{ scope.row.checkIn }}</div>
              <div>{{ scope.row.checkOut }}</div>
              <!-- <div>{{ scope.row.times.day }}</div> -->
            </template>
          </el-table-column>
          <el-table-column prop="guestContact" label="入住人">
          </el-table-column>
          <el-table-column label="渠道">
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.platform == 1
                    ? "艺龙"
                    : scope.row.platform == 2
                    ? "飞猪"
                    : scope.row.platform == 3
                    ? "美团"
                    : scope.row.platform == 4
                    ? "去哪儿"
                    : scope.row.platform == 5
                    ? "携程"
                    : ""
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="thirdOrderNum" label="渠道订单号">
          </el-table-column>
          <el-table-column label="总采购价">
            <template slot-scope="scope">
              <div>{{ scope.row.purchasePrice }}</div>
              <div
                class="cic"
                v-if="role_finance_examine_editmoney"
                @click="modified(scope.row)"
              >
                修改金额
              </div>
            </template>
          </el-table-column>
          <el-table-column label="回执">
            <template slot-scope="scope">
              <!-- <img
                :src="scope.row.voucherUrl"
                alt=""
                 slot="reference"
                v-if="scope.row.voucherUrl != ''"
              /> -->


              <el-popover  v-if="scope.row.voucherUrl != ''" placement="left" width="700" trigger="click">
                 <img
                 style="width:690px;"
                :src="scope.row.voucherUrl"
                alt=""
               
              />
                <img
                :src="scope.row.voucherUrl"
                alt=""
                 slot="reference"
               
              />
               
              </el-popover>

              <div
                v-if="
                  scope.row.voucherUrl == '' || scope.row.voucherUrl == null
                "
              >
                未上传
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="productManager" label="产品经理">
          </el-table-column>
          <el-table-column
            label="审核状态"
            prop="receiptState"
            :formatter="formatReceiptState"
          >
          </el-table-column>
          <el-table-column prop="operation" label="操作">
            <template slot-scope="scope">
              <div class="cic">
                <span
                  @click="toTongguo(scope.row)"
                  v-if="role_finance_examine_pass"
                  >通过</span
                >&nbsp;&nbsp;
                <span
                  @click="toBohui(scope.row)"
                  v-if="role_finance_examine_rejected"
                  >驳回</span
                >
              </div>
              <div
                v-if="
                  scope.row.voucherUrl == '' || scope.row.voucherUrl == null
                "
                class="cic"
              >
                <span
                  @click="toHuizhi(scope.row)"
                  v-if="role_finance_examine_uploadreceipt"
                  >上传回执</span
                >
              </div>
              <div v-if="scope.row.type == '通过'"></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <ReceiptDiaglog :getList="getList" ref="modified" />
      <Tongguo :getList="getList" ref="tongguo" />
      <Bohui :getList="getList" ref="bohui" />
      <UploadReceiptDiaglog :getList="getList" ref="upload" />
    </div>
  </div>
</template>

<script>
import {
  getReceiptOrderList,
  invoiceExportExcel,
  getGroups,
  getPlatformList,
} from "../../../apis/Finance";
import ReceiptDiaglog from "./ReceiptDiaglog.vue";
import ExamineLook from "./ExamineLook.vue";
import Tongguo from "./Tongguo.vue";
import Bohui from "./Bohui.vue";
import UploadReceiptDiaglog from "./UploadReceiptDiaglog.vue";
import local from "@/utils/local.js";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: {
    ReceiptDiaglog,
    ExamineLook,
    Tongguo,
    Bohui,
    UploadReceiptDiaglog,
  },
  data() {
    return {
      role_finance_examine_export: getRoleKey("role_finance_examine_export"),
      role_finance_examine_pass: getRoleKey("role_finance_examine_pass"),
      role_finance_examine_rejected: getRoleKey(
        "role_finance_examine_rejected"
      ),
      role_finance_examine_uploadreceipt: getRoleKey(
        "role_finance_examine_uploadreceipt"
      ),
      role_finance_examine_editmoney: getRoleKey(
        "role_finance_examine_editmoney"
      ),
      tableData: [],
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "已通过",
        },
        {
          value: "2",
          label: "驳回",
        },
      ],
      value: "",
      formInline: {
        name: "",
        ordernum: "",
        city: "",
        writeHotelName: "",
        channel: "",
        vest: "",
        timeType: "",
        times: "",
        job: "",
        group: "",
        people: "",
      },
      // 渠道
      channel: [
        { value: "1", label: "艺龙" },
        { value: "2", label: "飞猪" },
        { value: "3", label: "美团" },
        { value: "4", label: "去哪儿" },
        { value: "5", label: "携程" },
      ],
      // 马甲
      horseVestId: [],
      //时间
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 日期选择
      timeType: [
        { value: "1", label: "所有日期" },
        { value: "2", label: "下单日期" },
        { value: "3", label: "入住日期" },
        { value: "4", label: "离店日期" },
      ],
      // 职位
      job: [
        { value: "0", label: "产品" },
        { value: "1", label: "运营" },
      ],
      // 组别
      group: [],
      receiptState: null,
      Url: "",
      token: "",
      chooseArr: [],
    };
  },
  methods: {
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 获取列表
    async getList() {
      let { code, data } = await getReceiptOrderList({
        pageNumber: this.currentPage,
        pageSize: this.count,
        guestContact: this.formInline.name,
        number: this.formInline.ordernum,
        city: this.formInline.city,
        hotelName: this.formInline.writeHotelName,
        platform: this.formInline.channel,
        horseVestId: this.formInline.vest,
        dateType: this.formInline.timeType,
        startDate: this.formInline.times[0],
        endDate: this.formInline.times[1],
        position: this.formInline.job,
        groupId: this.formInline.group,
        managerName: this.formInline.people,
        receiptState: this.receiptState,
      });
      if (code == 0) {
        // console.log(data, "list111111111");
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    // 点击打开修改金额
    modified(row) {
      console.log(row);
      this.$refs.modified.open(row);
    },
    // 获取马甲id
    async getVestList() {
      let { code, data } = await getPlatformList({});
      if (code == 0) {
        console.log(data, "majia");
        if (this.formInline.channel == "1") {
          this.horseVestId = data[0].vestList;
        } else if (this.formInline.channel == "2") {
          this.horseVestId = data[1].vestList;
        } else if (this.formInline.channel == "3") {
          this.horseVestId = data[2].vestList;
        } else if (this.formInline.channel == "4") {
          this.horseVestId = data[3].vestList;
        } else if (this.formInline.channel == "5") {
          this.horseVestId = data[4].vestList;
        } else {
          this.horseVestId = [];
        }
        console.log(this.horseVestId, "111111111111");
      }
    },
    // 选择渠道刷新马甲
    choisePlatform() {
      this.getVestList();
      if (this.formInline.channel != "") {
        this.formInline.vest = "";
      }
    },
    // 获取组别
    async getGroup() {
      let { code, data } = await getGroups({
        positionType: this.formInline.job,
      });
      if (code == 0) {
        this.group = data;
      }
    },
    // 选择职位刷新组别
    choiseGroup() {
      this.getGroup();
      if (this.formInline.job != "") {
        this.formInline.group = "";
      }
    },
    // 选择type
    checkType(val) {
      this.receiptState = val;
    },
    // 搜索
    search() {
      this.getList();
    },
    // 导出
    async orderExport() {
      this.Url =
        "http://47.108.228.93:8080/otaHotel/finance/examine/receiptOrderExportExcel?token=" +
        this.token +
        "&pageNumber=" +
        this.currentPage +
        "&pageSize=" +
        this.count +
        "&guestContact=" +
        this.formInline.name +
        "&number=" +
        this.formInline.ordernum +
        "&city=" +
        this.formInline.city +
        "&hotelName=" +
        this.formInline.writeHotelName +
        "&platform=" +
        this.formInline.channel +
        "&horseVestId=" +
        this.formInline.vest +
        "&dateType=" +
        this.formInline.timeType +
        "&startDate=" +
        this.formInline.times[0] +
        "&endDate=" +
        this.formInline.times[1] +
        "&receiptState=" +
        this.receiptState +
        "&position=" +
        this.formInline.job +
        "&groupId=" +
        this.formInline.group +
        "&managerName=" +
        this.formInline.people;
    },
    // 点击通过打开弹窗
    toTongguo(row) {
      this.$refs.tongguo.open(row.id);
    },
    // 点击驳回打开弹窗
    toBohui(row) {
      this.$refs.bohui.open(row.id);
    },
    // 点击上传回执
    toHuizhi(row) {
      this.$refs.upload.open(row);
    },
    toTongguos() {
      if (this.chooseArr.length == 0) {
        this.$alert("请勾选需要通过的数据");
      } else {
        let orderIds = [];
        this.chooseArr.forEach((item) => {
          orderIds.push(item.id);
        });
        orderIds = orderIds.join(",");
        this.$refs.tongguo.open(orderIds);
      }
    },
    toBohuis() {
      if (this.chooseArr.length == 0) {
        this.$alert("请勾选需要驳回的数据");
      } else {
        let orderIds = [];
        this.chooseArr.forEach((item) => {
          orderIds.push(item.id);
        });
        orderIds = orderIds.join(",");
        this.$refs.bohui.open(orderIds);
      }
    },
    handleSelectionChange(val) {
      this.chooseArr = val;
    },
    formatReceiptState(row, column, cellValue) {
      if (cellValue == 0) {
        return "待审核";
      } else if (cellValue == 1) {
        return "通过";
      } else if (cellValue == 2) {
        return "驳回";
      }
    },
  },
  created() {
    this.getList();
    this.token = local.get("tk");
  },
};
</script>

<style lang="less" scoped>
.sec-box {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .el-pagination {
    padding: 10px;
    box-sizing: border-box;
  }
}
/deep/.el-table {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.cic {
  color: #256ef1 !important;
  cursor: pointer;
}
img {
  width: 72px;
}
a {
  width: 56px;
  height: 29px;
  font-size: 12px;
  border-radius: 3px;
  color: rgb(37, 110, 241) !important;
  text-align: center;
  line-height: 29px;
  border: none;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgb(37, 110, 241);
}
</style>