<template>
  <div>
    <el-dialog
      title="设置冲抵金额"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
    <el-row :gutter="20">
       <el-col :span="6" style="text-align:right">
           可使用冲抵金额
       </el-col>
       <el-col :span="18">
        {{availableOffsetPrice}}
       </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top:20px;">
       <el-col :span="6" style="text-align:right">
           冲抵金额
       </el-col>
       <el-col :span="18">
         <el-input size="mini"  style="width:200px;" v-model.trim="upData.produceOffsetPrice"></el-input>
       </el-col>
    </el-row>
     

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { produceOffsetPrice_api } from "../../../apis/Finance";
export default {
  data() {
    return {
      upData: {
        orderId: "",
        produceOffsetPrice: "",
      },

      dialogFormVisible: false,
      availableOffsetPrice:''
    };
  },
  methods: {
    open(id,availableOffsetPrice) {
      this.upData.orderId = id;
      this.dialogFormVisible = true;
      this.availableOffsetPrice=availableOffsetPrice
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      if (this.upData.produceOffsetPrice == "") {
        this.$message.warning("请填写冲抵金额");
      } else {
        let { code, data } = await produceOffsetPrice_api(this.upData);
        if (code == 0) {
          this.$message.success(data ? data : "提交成功");
          this.dialogFormVisible = false;
          this.$parent.getList()

        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>

