<template>
  <div>
    <el-dialog
      title="上传凭证"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
      @closed="cleanDataFn"
    >
      <el-upload
        ref="upload"
        :auto-upload="false"
        :file-list="fileList"
        :http-request="uploadFile"
        :on-change="handleChange"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        action="string"
        class="upload"
        multiple="multiple"
      >
        <el-button slot="trigger" size="small" type="primary" @click="delFile"
          >选取图片</el-button
        >
        <!-- <el-button
          size="small"
          style="margin-left: 10px"
          type="success"
          @click="submitUpImg"
          >上传图片</el-button
        > -->
      </el-upload>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="submitUpImg"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      fileList: [],
      imgUrl: "",
      dialogFormVisible: false,
      id: "",
      file: "",
    };
  },
  methods: {
    open(row) {
      this.id = row.id;
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    handEditData() {
      //   if (this.upData.remarks == "") {
      //     this.$message.warning("请填写备注");
      //   } else {
      //     let { code, data } = await addOrderLog_api(this.upData);
      //     if (code == 0) {
      //       this.$message.success(data ? data : "提交成功");
      //       this.dialogFormVisible = false;
      //     }
      //   }
    },

    delFile() {
      this.fileList = [];
    },

    uploadFile(file) {
      this.formData.append("file", file.file);
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },

    handlePreview(file) {
      console.log(file);
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    submitUpImg() {
      if (this.fileList[0]) {
        const getListLoading = this.$loading({
          lock: true,
          text: "上传文件中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let formData = new FormData();

        formData.append("multipartFile", this.fileList[0].raw);
        let multipartFile = formData;
        this.$axios({
          method: "post",
          url: "/otaHotel/finance/examine/upload?orderId=" + this.id,
          data: multipartFile,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          if (res.data.code == 0) {
            this.getList();
            getListLoading.close();
            this.imgUrl = res.data.data.url;
            this.$emit("func", this.imgUrl);
            this.dialogFormVisible = false;
          }
        });
      } else {
        this.$message.warning("请选择凭证");
      }
    },

    cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>
