<template>
  <div>
    <!-- 预付款 对账弹窗 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="25%"
      :before-close="handleClose"
    >
      <div style="width: 100%; text-align: center">是否确认对账</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogVisible: false,
      id: "",
    };
  },
  methods: {
    //   打开弹窗
    open(row) {
      // console.log(row,'111111111');
      this.id = row.id;
      this.dialogVisible = true;
    },
    // 确认
    sure() {
      this.$axios({
        method: "post",
        url: "/otaHotel/finance/advance/reconciliation?orderId=" + this.id,
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      })
        .then((res) => {
          console.log(res);
          this.getList();
        })
        .catch((error) => {});
      this.dialogVisible = false;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
</style>