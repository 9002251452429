<template>
  <!-- 转款 -->
  <div>
    <el-dialog title="线上转款" :visible.sync="dialogVisible" width="30%">
      <div class="cont">
        <div>
          <span>转款账户</span>
          <el-select
            v-model="value"
            placeholder="请选择"
            size="mini"
            @change="choiseId"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.accountName + '(' + item.appId + ')'"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>转款金额</span>
          <el-input size="mini" v-model="acount"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="close">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import {
  getPayConfigListAndOffsetPrice,
  advanceHotelTransfer_api,
} from "../../../apis/Finance";
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogVisible: false,
      options: [],
      value: "",
      id: "",
      arr: [],
      acount: "",
    };
  },
  methods: {
    //打开弹窗
    async open(row) {
      this.hotelSonId = row.hotelSonId;
      // 获取数据;
      let { code, data } = await getPayConfigListAndOffsetPrice({
        // orderId: row.id,
      });
      if (code == 0) {
        // console.log(data, "list");
        this.options = data.payConfigs;
      }
      this.dialogVisible = true;
    },
    // 确定
    async close() {
      if (this.acount == "" || this.value == "") {
        this.$message.error("请完善信息");
      } else {
        let { code, data } = await advanceHotelTransfer_api(
          this.hotelSonId,
          this.acount,
          this.value
        );
        if (code == 0) {
          this.$message({
            type: "success",
            message: data ? data : "转账成功",
          });
          this.dialogVisible = false;
          this.value = "";
          this.$parent.getList();
        }
      }
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
      this.arr = [];
    },
    // 选择账户
    choiseId(val) {
      this.id = val;
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.cont {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    margin: 5px 0;
    span {
      width: 100px;
      text-align: right;
      padding: 0 10px;
    }
  }
}
.el-select {
  width: 100% !important;
}
</style>